<template>
    <div class="modal fade common-modal" id="chatModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close-svg" data-bs-dismiss="modal" aria-label="Close">

                        <svg xmlns="http://www.w3.org/2000/svg" width="7.905" height="7.905" viewBox="0 0 7.905 7.905">
                            <g id="Group_1469" data-name="Group 1469" transform="translate(-1480.904 -467.983)">
                                <line id="Line_340" data-name="Line 340" x1="5.784" y2="5.784"
                                    transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line id="Line_341" data-name="Line 341" x1="5.784" y1="5.784"
                                    transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <div class="heading">
                        <h3>
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 27.99 28">
                                <path class="cls-1"
                                    d="M861.06,566.12a10.38,10.38,0,0,1-3.7-.6c-.83-.29-1.65-.6-2.38-.88a18.84,18.84,0,0,1-5.08-2.93,37.75,37.75,0,0,1-5.19-5.12,23.36,23.36,0,0,1-4.13-8,8.76,8.76,0,0,1-.49-3.34,9,9,0,0,1,2.55-5.63,3.09,3.09,0,0,1,1.37-.86c.73-.21,1.46-.37,2.15-.51a2.74,2.74,0,0,1,.66-.09,2.34,2.34,0,0,1,2.07,1.29l.16.29c.29.53.55,1,.81,1.45l1.85,3.12a2.38,2.38,0,0,1-.31,3c-.38.39-.78.76-1.18,1.12l-1.19,1,.27.45c.52.88,1,1.71,1.57,2.49a14.79,14.79,0,0,0,4.63,4.1c.37.22.75.43,1.18.66l.08,0,1.15-1.29a12.5,12.5,0,0,1,.87-1,2.55,2.55,0,0,1,1.78-.84,3,3,0,0,1,1.54.49l4.08,2.39.72.41a2.26,2.26,0,0,1,1.16,2.19,8.87,8.87,0,0,1-.71,2.95,3.18,3.18,0,0,1-.78,1.09,9,9,0,0,1-5.49,2.51ZM846.82,540a1.19,1.19,0,0,0-.26,0c-.68.14-1.36.28-2,.47a1.38,1.38,0,0,0-.54.36,6.63,6.63,0,0,0-1.65,7.13,21.58,21.58,0,0,0,3.79,7.41,34.5,34.5,0,0,0,4.92,4.86,16.63,16.63,0,0,0,4.43,2.6c.89.34,1.69.64,2.49.92a8.33,8.33,0,0,0,3,.5,7.15,7.15,0,0,0,4.3-2,1.45,1.45,0,0,0,.33-.46,7.11,7.11,0,0,0,.56-2.34A.42.42,0,0,0,866,559l-.71-.41-4.1-2.4a1.27,1.27,0,0,0-.58-.23c-.16,0-.37.18-.52.32a10.39,10.39,0,0,0-.72.78l-.76.87c-.28.31-.5.57-.73.81a1.27,1.27,0,0,1-1,.45l-.38-.07-.26-.11c-.64-.34-1.17-.63-1.69-.94a16.44,16.44,0,0,1-5.2-4.62c-.6-.85-1.16-1.78-1.64-2.6l-.5-.84a1.29,1.29,0,0,1,.29-1.71l.73-.64L849,547c.42-.38.78-.7,1.11-1a.56.56,0,0,0,0-.74l-1.86-3.13c-.28-.5-.56-1-.83-1.5l-.16-.28A.51.51,0,0,0,846.82,540Z"
                                    transform="translate(-840.07 -538.12)" />
                            </svg>
                            Phone
                        </h3>
                    </div>
                    <div class="stick-bar-menu">
                        <div class="customer-name-edit d-flex align-items-center">
                            <p class="font-sizeW-18" style="color: var(--colorD) !important;">
                                {{ custName }}
                            </p>
                            <div class="ms-auto">
                                <a>
                                    <button v-if="!isPhoneNumber(custName)" class="d-contents" data-bs-toggle="modal"
                                        data-bs-target="#editCustomerModal" @click="getDatas(this.customerId)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.504" height="17.504"
                                            viewBox="0 0 17.504 17.504">
                                            <g id="Group_2096" data-name="Group 2096"
                                                transform="translate(4781.272 4896.079)">
                                                <path id="Path_1693" data-name="Path 1693"
                                                    d="M-4764.407-4890.8l-.773.774-4.642-4.642.773-.774a2.191,2.191,0,0,1,3.1,0l1.546,1.547A2.193,2.193,0,0,1-4764.407-4890.8Zm-10.831,9.281a.546.546,0,0,0,0,.774.545.545,0,0,0,.773,0l8.512-8.508-.776-.773Zm-3.869-3.867a.547.547,0,0,0,0,.774.547.547,0,0,0,.774,0l8.511-8.508-.774-.773Zm3.092,5.413a1.59,1.59,0,0,1-.434-.936,2.27,2.27,0,0,1-.338.033,2.171,2.171,0,0,1-1.546-.641,2.176,2.176,0,0,1-.642-1.547,2.164,2.164,0,0,1,.031-.314,1.632,1.632,0,0,1-.938-.459.71.71,0,0,1-.055-.084l-1.335,5.343,5.33-1.335C-4775.965-4879.93-4775.992-4879.946-4776.015-4879.969Z"
                                                    fill="#d5a948" />
                                            </g>
                                        </svg>
                                    </button>
                                    <button v-else class="d-contents" data-bs-toggle="modal"
                                        data-bs-target="#addCustomerModal"
                                        @click="openNewCustomer(this.customerPhones)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.504" height="17.504"
                                            viewBox="0 0 17.504 17.504">
                                            <g id="Group_2096" data-name="Group 2096"
                                                transform="translate(4781.272 4896.079)">
                                                <path id="Path_1693" data-name="Path 1693"
                                                    d="M-4764.407-4890.8l-.773.774-4.642-4.642.773-.774a2.191,2.191,0,0,1,3.1,0l1.546,1.547A2.193,2.193,0,0,1-4764.407-4890.8Zm-10.831,9.281a.546.546,0,0,0,0,.774.545.545,0,0,0,.773,0l8.512-8.508-.776-.773Zm-3.869-3.867a.547.547,0,0,0,0,.774.547.547,0,0,0,.774,0l8.511-8.508-.774-.773Zm3.092,5.413a1.59,1.59,0,0,1-.434-.936,2.27,2.27,0,0,1-.338.033,2.171,2.171,0,0,1-1.546-.641,2.176,2.176,0,0,1-.642-1.547,2.164,2.164,0,0,1,.031-.314,1.632,1.632,0,0,1-.938-.459.71.71,0,0,1-.055-.084l-1.335,5.343,5.33-1.335C-4775.965-4879.93-4775.992-4879.946-4776.015-4879.969Z"
                                                    fill="#d5a948" />
                                            </g>
                                        </svg>
                                    </button>
                                </a>
                                <a href="#"
                                    @click="emitMakeCallClick(customerPhones); sendCustomerNumber(customerPhones)"
                                    data-bs-toggle="modal" data-bs-target="#modelOpenSecond">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.208" height="17.211"
                                        viewBox="0 0 17.208 17.211">
                                        <path id="Path_1692" data-name="Path 1692"
                                            d="M-4702.779-4879.321a5.68,5.68,0,0,1-2.054-.338c-.539-.187-1.077-.392-1.541-.567a10.719,10.719,0,0,1-2.86-1.677,21.963,21.963,0,0,1-3.1-3.057,13.674,13.674,0,0,1-2.423-4.731,4.852,4.852,0,0,1-.277-1.826,4.968,4.968,0,0,1,1.412-3.1,1.369,1.369,0,0,1,.588-.372c.428-.122.865-.214,1.288-.3a1.188,1.188,0,0,1,.269-.036.871.871,0,0,1,.772.5l.1.178c.165.3.331.6.5.9l1.133,1.915a.9.9,0,0,1-.109,1.14c-.228.238-.472.459-.708.673,0,0-.316.282-.428.378-.189.163-.324.28-.456.4-.149.136-.139.23-.074.338l.3.5c.312.524.633,1.062.988,1.561a9.578,9.578,0,0,0,3.006,2.67c.316.187.636.362,1.02.569l.024.013.1.026a.239.239,0,0,0,.173-.094c.149-.157.292-.321.436-.484l.44-.5c.156-.181.317-.367.5-.541a1.031,1.031,0,0,1,.711-.353,1.278,1.278,0,0,1,.645.216c.747.441,2.5,1.464,2.5,1.464l.446.256a.817.817,0,0,1,.422.806,4.914,4.914,0,0,1-.386,1.618,1.442,1.442,0,0,1-.343.476,4.94,4.94,0,0,1-3,1.39Z"
                                            transform="translate(4715.639 4895.932)" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="chat-main d-flex flex-column">
                        <div class="chat-list">
                            <div class="chat-main d-flex flex-column" v-if="smsLogDetials && smsLogDetials.length">
                                <div class="chat-list" v-for="(log, index) in smsLogDetials" :key="index">
                                    <div class="chat-text left d-flex align-items-start"
                                        v-if="log.status == 'Incoming'">
                                        <figure><img src="images/dummy-user.svg" alt="Hanna"></figure>
                                        <div class="text">
                                            <span>{{ log.created_at }}</span>
                                            <p :class="{ 'd-none': log.type == 'call' }">{{ log.text }}</p>
                                            <div class="chat-activity" v-if="log.type == 'call'">
                                                <p class="call-alert">
                                                    <IncomingCallIcon
                                                        :class="{ 'd-none': log.type != 'call' || log.status != 'Incoming' || log.call_duration == 0 }" />
                                                    <OutgoingCallIcon
                                                        :class="{ 'd-none': log.status !== 'Outgoing' }" />
                                                    <MissCallIcon
                                                        :class="{ 'd-none': log.type != 'call' || log.call_duration != 0 || log.status != 'Incoming' }" />
                                                    <label class="pl-1" :class="{ 'd-none': log.type == 'sms' }"
                                                        v-if="log.call_duration == 0 && log.status == 'Incoming'">Miss
                                                        call</label>
                                                    <label class="pl-1" :class="{ 'd-none': log.type == 'sms' }"
                                                        v-else>{{ log.status }} call</label>

                                                </p>
                                                <p class="wait">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.083"
                                                        height="13.409" viewBox="0 0 10.083 13.409">
                                                        <path id="Path_637" data-name="Path 637"
                                                            d="M-1764.559-6884.441a.289.289,0,0,1-.29-.289.29.29,0,0,1,.29-.289h.993v-1.635a4.434,4.434,0,0,1,2.189-3.65,1,1,0,0,0,.376-.842,1,1,0,0,0-.376-.841,4.434,4.434,0,0,1-2.189-3.65v-1.636h-.993a.289.289,0,0,1-.29-.288.289.289,0,0,1,.29-.289h9.5a.289.289,0,0,1,.29.289.289.289,0,0,1-.29.288h-.993v1.636a4.436,4.436,0,0,1-2.189,3.65,1,1,0,0,0-.376.841,1,1,0,0,0,.376.842,4.436,4.436,0,0,1,2.189,3.65v1.635h.993a.29.29,0,0,1,.29.289.289.289,0,0,1-.29.289Zm1.572-11.2a3.97,3.97,0,0,0,1.933,3.17,1.563,1.563,0,0,1,.633,1.321,1.563,1.563,0,0,1-.633,1.322,3.97,3.97,0,0,0-1.933,3.17v1.635h6.359v-1.635a3.97,3.97,0,0,0-1.933-3.17,1.563,1.563,0,0,1-.633-1.322,1.563,1.563,0,0,1,.633-1.321,3.97,3.97,0,0,0,1.933-3.17v-1.636h-6.359Z"
                                                            transform="translate(1764.849 6897.85)" fill="#d5a948" />
                                                    </svg>
                                                    {{ formatCallDuration(log.call_duration) }}
                                                </p>
                                                <a href="#" class="attach"
                                                    @click.prevent="copyToClipboard(log.recording_url, log.id)">
                                                    <span class="tooltip" v-if="copiedId === log.id">Copied!</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.26"
                                                        height="14.259" viewBox="0 0 14.26 14.259">
                                                        <g id="Group_766" data-name="Group 766"
                                                            transform="translate(1664.792 6897.352)">
                                                            <path id="Path_639" data-name="Path 639"
                                                                d="M-1656.9-6887.665a3.336,3.336,0,0,1-2.005-.674l-.211-.16,1.032-1.036.159.1a1.891,1.891,0,0,0,1.024.3,1.868,1.868,0,0,0,1.315-.528l3.056-3.057a1.84,1.84,0,0,0,.542-1.312,1.86,1.86,0,0,0-1.856-1.859,1.837,1.837,0,0,0-1.313.545l-1.611,1.61-.136-.045a4.748,4.748,0,0,0-1.109-.23l-.5-.042,2.324-2.324a3.3,3.3,0,0,1,2.344-.971,3.321,3.321,0,0,1,3.316,3.316,3.293,3.293,0,0,1-.971,2.343l-3.057,3.057A3.293,3.293,0,0,1-1656.9-6887.665Z"
                                                                fill="#d5a948" />
                                                            <path id="Path_640" data-name="Path 640"
                                                                d="M-1661.476-6883.093a3.32,3.32,0,0,1-3.316-3.315,3.294,3.294,0,0,1,.971-2.344l3.057-3.057a3.329,3.329,0,0,1,2.345-.938,3.383,3.383,0,0,1,2,.641l.218.161-1.034,1.035-.157-.1a1.892,1.892,0,0,0-1.024-.295,1.859,1.859,0,0,0-1.314.528l-3.056,3.056a1.841,1.841,0,0,0-.544,1.314,1.859,1.859,0,0,0,1.857,1.856,1.838,1.838,0,0,0,1.312-.543l1.595-1.593.138.048a4.525,4.525,0,0,0,1.095.241l.493.045-2.288,2.289A3.3,3.3,0,0,1-1661.476-6883.093Z"
                                                                fill="#d5a948" />
                                                        </g>
                                                    </svg>

                                                </a>
                                                <a href="#" class="play" v-if="activeId !== log.id"
                                                    @click.prevent="log.recording_url !== null ? playAudio(log.id) : null"
                                                    title="Play Recording">
                                                    <svg height="20px" width="80px" version="1.1" id="_x32_"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"
                                                        xml:space="preserve">
                                                        <g>
                                                            <path class="st0"
                                                                d="M378.409,0H208.294h-13.176l-9.314,9.314L57.017,138.102l-9.315,9.314v13.176v265.513
                                                                c0,47.361,38.528,85.896,85.896,85.896h244.811c47.361,0,85.888-38.535,85.888-85.896V85.895C464.298,38.528,425.77,0,378.409,0z
                                                                M432.494,426.104c0,29.877-24.214,54.092-54.084,54.092H133.598c-29.877,0-54.091-24.215-54.091-54.092V160.591h83.717
                                                                c24.884,0,45.07-20.179,45.07-45.07V31.804h170.115c29.87,0,54.084,24.214,54.084,54.091V426.104z" />
                                                            <path class="st0" d="M228.222,229.171c-0.705-0.406-1.557-0.426-2.262-0.035c-0.712,0.391-1.117,1.131-1.117,1.948v56.73v56.752
                                                                c0,0.817,0.405,1.544,1.117,1.928c0.705,0.412,1.557,0.391,2.262,0l95.042-56.766c0.677-0.405,1.082-1.131,1.082-1.914
                                                                c0-0.775-0.404-1.522-1.082-1.906L228.222,229.171z" />
                                                        </g>
                                                    </svg>
                                                </a>
                                                <a href="#" class="play second mt-0" v-if="activeId === log.id"
                                                    title="close" @click="hideClosed">
                                                    <svg fill="#000000" height="30px" width="80px" version="1.1"
                                                        id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 492 492"
                                                        xml:space="preserve">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
                                                                    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
                                                                    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
                                                                    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
                                                                    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
                                                                    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
                                                                    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="chat-activity audioPlayer"
                                                v-if="activeId === log.id && log.recording_url">
                                                <audio id="audioPlayer" controls>
                                                    <source :src="log.recording_url" type="audio/mp3">
                                                </audio>
                                            </div>
                                            <!-- <div class="chat-activity media-mms"
                                                v-if="activeId === log.id && log.recording_url">
                                                <audio id="audioPlayer" controls>
                                                    <source :src="log.recording_url" type="audio/mp3">
                                                </audio>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="chat-text right" v-else>
                                        <figure><img :src="headerImage" alt="Hanna"></figure>
                                        <div class="text" v-if="log.status === 'Outgoing'">
                                            <span>{{ log.created_at }}</span>
                                            <p class="float-right" :class="{ 'd-none': log.type == 'call' }">
                                                {{ log.text }}
                                            </p>
                                            <div class="chat-activity" v-if="log.type == 'call'">
                                                <p class="call-alert">
                                                    <IncomingCallIcon
                                                        :class="{ 'd-none': log.status !== 'Incoming' }" />
                                                    <OutgoingCallIcon
                                                        :class="{ 'd-none': log.status !== 'Outgoing' }" />
                                                    {{ log.status }} call
                                                </p>
                                                <p class="wait">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.083"
                                                        height="13.409" viewBox="0 0 10.083 13.409">
                                                        <path id="Path_637" data-name="Path 637"
                                                            d="M-1764.559-6884.441a.289.289,0,0,1-.29-.289.29.29,0,0,1,.29-.289h.993v-1.635a4.434,4.434,0,0,1,2.189-3.65,1,1,0,0,0,.376-.842,1,1,0,0,0-.376-.841,4.434,4.434,0,0,1-2.189-3.65v-1.636h-.993a.289.289,0,0,1-.29-.288.289.289,0,0,1,.29-.289h9.5a.289.289,0,0,1,.29.289.289.289,0,0,1-.29.288h-.993v1.636a4.436,4.436,0,0,1-2.189,3.65,1,1,0,0,0-.376.841,1,1,0,0,0,.376.842,4.436,4.436,0,0,1,2.189,3.65v1.635h.993a.29.29,0,0,1,.29.289.289.289,0,0,1-.29.289Zm1.572-11.2a3.97,3.97,0,0,0,1.933,3.17,1.563,1.563,0,0,1,.633,1.321,1.563,1.563,0,0,1-.633,1.322,3.97,3.97,0,0,0-1.933,3.17v1.635h6.359v-1.635a3.97,3.97,0,0,0-1.933-3.17,1.563,1.563,0,0,1-.633-1.322,1.563,1.563,0,0,1,.633-1.321,3.97,3.97,0,0,0,1.933-3.17v-1.636h-6.359Z"
                                                            transform="translate(1764.849 6897.85)" fill="#d5a948" />
                                                    </svg>
                                                    {{ formatCallDuration(log.call_duration) }}
                                                </p>
                                                <a href="#" class="attach"
                                                    @click.prevent="copyToClipboard(log.recording_url, log.id)">
                                                    <span class="tooltip" v-if="copiedId === log.id">Copied!</span>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.26"
                                                        height="14.259" viewBox="0 0 14.26 14.259">
                                                        <g id="Group_766" data-name="Group 766"
                                                            transform="translate(1664.792 6897.352)">
                                                            <path id="Path_639" data-name="Path 639"
                                                                d="M-1656.9-6887.665a3.336,3.336,0,0,1-2.005-.674l-.211-.16,1.032-1.036.159.1a1.891,1.891,0,0,0,1.024.3,1.868,1.868,0,0,0,1.315-.528l3.056-3.057a1.84,1.84,0,0,0,.542-1.312,1.86,1.86,0,0,0-1.856-1.859,1.837,1.837,0,0,0-1.313.545l-1.611,1.61-.136-.045a4.748,4.748,0,0,0-1.109-.23l-.5-.042,2.324-2.324a3.3,3.3,0,0,1,2.344-.971,3.321,3.321,0,0,1,3.316,3.316,3.293,3.293,0,0,1-.971,2.343l-3.057,3.057A3.293,3.293,0,0,1-1656.9-6887.665Z"
                                                                fill="#d5a948" />
                                                            <path id="Path_640" data-name="Path 640"
                                                                d="M-1661.476-6883.093a3.32,3.32,0,0,1-3.316-3.315,3.294,3.294,0,0,1,.971-2.344l3.057-3.057a3.329,3.329,0,0,1,2.345-.938,3.383,3.383,0,0,1,2,.641l.218.161-1.034,1.035-.157-.1a1.892,1.892,0,0,0-1.024-.295,1.859,1.859,0,0,0-1.314.528l-3.056,3.056a1.841,1.841,0,0,0-.544,1.314,1.859,1.859,0,0,0,1.857,1.856,1.838,1.838,0,0,0,1.312-.543l1.595-1.593.138.048a4.525,4.525,0,0,0,1.095.241l.493.045-2.288,2.289A3.3,3.3,0,0,1-1661.476-6883.093Z"
                                                                fill="#d5a948" />
                                                        </g>
                                                    </svg>

                                                </a>
                                                <a href="#" class="play" v-if="activeId !== log.id"
                                                    @click.prevent="log.recording_url !== null ? playAudio(log.id) : null"
                                                    title="Play Recording">
                                                    <svg height="20px" width="80px" version="1.1" id="_x32_"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"
                                                        xml:space="preserve">
                                                        <g>
                                                            <path class="st0"
                                                                d="M378.409,0H208.294h-13.176l-9.314,9.314L57.017,138.102l-9.315,9.314v13.176v265.513
                                                                c0,47.361,38.528,85.896,85.896,85.896h244.811c47.361,0,85.888-38.535,85.888-85.896V85.895C464.298,38.528,425.77,0,378.409,0z
                                                                M432.494,426.104c0,29.877-24.214,54.092-54.084,54.092H133.598c-29.877,0-54.091-24.215-54.091-54.092V160.591h83.717
                                                                c24.884,0,45.07-20.179,45.07-45.07V31.804h170.115c29.87,0,54.084,24.214,54.084,54.091V426.104z" />
                                                            <path class="st0" d="M228.222,229.171c-0.705-0.406-1.557-0.426-2.262-0.035c-0.712,0.391-1.117,1.131-1.117,1.948v56.73v56.752
                                                                c0,0.817,0.405,1.544,1.117,1.928c0.705,0.412,1.557,0.391,2.262,0l95.042-56.766c0.677-0.405,1.082-1.131,1.082-1.914
                                                                c0-0.775-0.404-1.522-1.082-1.906L228.222,229.171z" />
                                                        </g>
                                                    </svg>
                                                </a>
                                                <a href="#" class="play second mt-0" v-if="activeId === log.id"
                                                    title="close" @click="hideClosed">
                                                    <svg fill="#000000" height="30px" width="80px" version="1.1"
                                                        id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 492 492"
                                                        xml:space="preserve">
                                                        <g>
                                                            <g>
                                                                <path
                                                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872
                                                                    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872
                                                                    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052
                                                                    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116
                                                                    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952
                                                                    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116
                                                                    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </a>
                                            </div>
                                            <div class="chat-activity audioPlayer"
                                                v-if="activeId === log.id && log.recording_url">
                                                <audio id="audioPlayer" controls>
                                                    <source :src="log.recording_url" type="audio/mp3">
                                                </audio>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="chat-text right mms"
                                        v-if="log.type === 'mms' && log.media_urls && log.media_urls.length > 0 && log.media_urls[0] !== '[]'">
                                        <figure><img :src="headerImage" alt="Hanna" style="margin-top: -35px;"></figure>
                                        <div class="text">
                                            <span style="line-height: unset;">{{ log.created_at }}</span>
                                            <img :src="log.media_urls" class="media-image" type="file">
                                        </div>
                                    </div>
                                </div>
                                <div ref="scrollElement"></div>
                            </div>
                            <div v-else>
                                <p>No data available.</p>
                            </div>
                        </div>
                        <div class="chat-message mt-auto">
                            <form>
                                <div class="height-upper">
                                    <input type="text" class="form-control" placeholder="Message"
                                        v-model="messageContent">
                                    <!-- <input type="text" class="form-control" placeholder="Message"
                                        v-model="messageContent"> -->
                                </div>
                                <label class="attachment">
                                    <input type="file" class="d-none" @change="handleFileUpload" ref="fileInput">
                                    <svg v-if="!selectedFile" xmlns="http://www.w3.org/2000/svg" width="23.642"
                                        height="17.737" viewBox="0 0 23.642 17.737">
                                        <g id="Group_760" data-name="Group 760" transform="translate(1684.605 6408.97)">
                                            <path id="Path_631" data-name="Path 631"
                                                d="M-1682.258-6393.6v-1.015c0-1.109,0-2.255.006-3.381v-.188l.141-.121c1.913-1.535,3.429-2.749,4.638-3.714l.258-.2s1.836,1.466,2.459,1.965l2.313-1.957.009-.007,2.37-2.005,1.723-1.457.262.242.353.316.009.008c.136.123.268.241.4.358l1.046.942.886.8c.628.565,1.265,1.138,1.9,1.705a.514.514,0,0,1,.183.411c-.006,1.92-.006,3.867-.006,5.749v1.551Zm17.841-1.113v-5.943c-1.054-.948-1.91-1.718-2.69-2.423l-.951-.859c-.1-.092-.2-.187-.308-.277l-6.368,5.389-2.479-1.988c-1.18.941-2.5,2-3.938,3.152v2.949Z"
                                                fill="#d5a948" />
                                            <path id="Path_632" data-name="Path 632"
                                                d="M-1680.176-6402.466a2.081,2.081,0,0,1-2.082-2.076,2.081,2.081,0,0,1,2.076-2.081h.006a2.075,2.075,0,0,1,2.076,2.07,2.087,2.087,0,0,1-.6,1.471A2.053,2.053,0,0,1-1680.176-6402.466Zm-.006-3.05a.978.978,0,0,0-.974.98.978.978,0,0,0,.98.975.954.954,0,0,0,.687-.288.991.991,0,0,0,.287-.693A.978.978,0,0,0-1680.182-6405.516Z"
                                                fill="#d5a948" />
                                            <path id="Path_633" data-name="Path 633"
                                                d="M-1682.091-6391.233a2.517,2.517,0,0,1-2.514-2.514v-12.709a2.517,2.517,0,0,1,2.514-2.514h18.614a2.517,2.517,0,0,1,2.514,2.514v12.709a2.517,2.517,0,0,1-2.514,2.514Zm0-16.7a1.478,1.478,0,0,0-1.476,1.476v12.709a1.478,1.478,0,0,0,1.476,1.476h18.614a1.478,1.478,0,0,0,1.476-1.476v-12.709a1.478,1.478,0,0,0-1.476-1.476Z"
                                                fill="#d5a948" />
                                        </g>
                                    </svg>

                                </label>
                                <button type="button" @click="sendMessage(customerPhones); getSmsList();"
                                    class="send-message">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.599" height="13.653"
                                        viewBox="0 0 15.599 13.653">
                                        <g id="Group_759" data-name="Group 759"
                                            transform="translate(1624.698 6406.928)">
                                            <g id="Group_758" data-name="Group 758">
                                                <path id="Path_630" data-name="Path 630"
                                                    d="M-1609.6-6400.1a.432.432,0,0,0-.261-.4l-13.727-5.883a.432.432,0,0,0-.464.081.431.431,0,0,0-.113.457l3.633,5.741-3.633,5.742a.43.43,0,0,0,.1.446l.011.011a.434.434,0,0,0,.464.081l13.727-5.883A.432.432,0,0,0-1609.6-6400.1Z"
                                                    fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1" />
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                                <div class="selectmediafile" v-if="selectedFile">
                                    <img class="smediafileImg" :src="imageUrl">
                                    <button class="selectedFileM" v-if="selectedFile" @click="removeImage">
                                        <i class="fa-solid fa-xmark"></i>
                                    </button>
                                </div>
                            </form>
                            <div v-if="showDialPad">
                                <div class="dial-pad-buttons">
                                    <div class="container">
                                        <div class="row">
                                            <div class="d-flex justify-content-center">
                                                <label class="d-flex align-items-center pr-3 font-sizeW-18">To</label>
                                                <span class="form-control tel text-center p-0"
                                                    style="line-height: 2.5;">{{ customerPhones }} </span>
                                                <label class="d-flex align-items-center pl-3 delete-back" disabled
                                                    @click="backRemove()">
                                                    <svg width="25px" height="20px" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
                                                        <path
                                                            d="M12.0004 9.5L17.0004 14.5M17.0004 9.5L12.0004 14.5M4.50823 13.9546L7.43966 17.7546C7.79218 18.2115 7.96843 18.44 8.18975 18.6047C8.38579 18.7505 8.6069 18.8592 8.84212 18.9253C9.10766 19 9.39623 19 9.97336 19H17.8004C18.9205 19 19.4806 19 19.9084 18.782C20.2847 18.5903 20.5907 18.2843 20.7824 17.908C21.0004 17.4802 21.0004 16.9201 21.0004 15.8V8.2C21.0004 7.0799 21.0004 6.51984 20.7824 6.09202C20.5907 5.71569 20.2847 5.40973 19.9084 5.21799C19.4806 5 18.9205 5 17.8004 5H9.97336C9.39623 5 9.10766 5 8.84212 5.07467C8.6069 5.14081 8.38579 5.2495 8.18975 5.39534C7.96843 5.55998 7.79218 5.78846 7.43966 6.24543L4.50823 10.0454C3.96863 10.7449 3.69883 11.0947 3.59505 11.4804C3.50347 11.8207 3.50347 12.1793 3.59505 12.5196C3.69883 12.9053 3.96863 13.2551 4.50823 13.9546Z"
                                                            stroke="#000000" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" />
                                                    </svg>
                                                </label>
                                            </div>
                                            <div v-if="forCallingStatus"
                                                class="d-none d-flex justify-content-between mt-3 py-1 px-2 bgcolor-yellow color-D radius-5">
                                                <span style=" display: block; margin: auto;"
                                                    id="callDuration">00:00:00</span>
                                            </div>
                                            <div class="col-md-12 col-md-offset-4 phone">
                                                <div class="row1">
                                                    <div class="col-md-12">
                                                        <div class="num-pad">
                                                            <div v-for="(row, index) in numPadRows" :key="index"
                                                                class="d-flex justify-content-center">
                                                                <div v-for="num in row" :key="num" class="span4">
                                                                    <div class="num" disabled
                                                                        @click="handleNumberClick(num)">
                                                                        <div class="txt">{{ num }}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="clear-flexed col-md-5 mx-auto">
                                                            <button class="btn btn-success btn-block flatbtn bg-red"
                                                                @click="hangup_Call()">
                                                                <!-- <svg width="20px" height="20px" viewBox="0 0 15 15"
                                                                    version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink">
                                                                    <g id="Page-1" stroke="none" stroke-width="1"
                                                                        fill="none" fill-rule="evenodd">
                                                                        <g id="Dribbble-Light-Preview"
                                                                            transform="translate(-103.000000, -7321.000000)"
                                                                            fill="#000000">
                                                                            <g id="icons"
                                                                                transform="translate(56.000000, 160.000000)">
                                                                                <path
                                                                                    d="M61.7302966,7173.99596 C61.2672966,7175.40296 59.4532966,7176.10496 58.1572966,7175.98796 C56.3872966,7175.82796 54.4612966,7174.88896 52.9992966,7173.85496 C50.8502966,7172.33496 48.8372966,7169.98396 47.6642966,7167.48896 C46.8352966,7165.72596 46.6492966,7163.55796 47.8822966,7161.95096 C48.3382966,7161.35696 48.8312966,7161.03996 49.5722966,7161.00296 C50.6002966,7160.95296 50.7442966,7161.54096 51.0972966,7162.45696 C51.3602966,7163.14196 51.7112966,7163.84096 51.9072966,7164.55096 C52.2742966,7165.87596 50.9912966,7165.93096 50.8292966,7167.01396 C50.7282966,7167.69696 51.5562966,7168.61296 51.9302966,7169.09996 C52.6632966,7170.05396 53.5442966,7170.87696 54.5382966,7171.50296 C55.1072966,7171.86196 56.0262966,7172.50896 56.6782966,7172.15196 C57.6822966,7171.60196 57.5872966,7169.90896 58.9912966,7170.48196 C59.7182966,7170.77796 60.4222966,7171.20496 61.1162966,7171.57896 C62.1892966,7172.15596 62.1392966,7172.75396 61.7302966,7173.99596 C61.4242966,7174.92396 62.0362966,7173.06796 61.7302966,7173.99596"
                                                                                    id="call-icon"></path>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg> -->
                                                                <svg width="20px" height="20px" version="1.1"
                                                                    id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                    y="0px" viewBox="0 0 512 512"
                                                                    style="enable-background:new 0 0 512 512;"
                                                                    xml:space="preserve">
                                                                    <g>
                                                                        <path
                                                                            d="M478.1,270.1c3.5,24.4,5.8,57.7-5.1,70.5c-18.1,21.1-132.6,21.1-132.6-21.1c0-21.3,18.8-35.2,0.8-56.3
                                                                            C323.4,242.4,291.5,242,256,242s-67.3,0.3-85.1,21.1c-18.1,21.1,0.8,35.1,0.8,56.3c0,42.2-114.5,42.2-132.6,21.1
                                                                            c-10.9-12.8-8.6-46.1-5.1-70.5c2.7-16.3,9.5-33.8,31.4-56.3l0,0c32.9-30.7,82.6-55.7,188.9-56.3l0,0c0.6,0,1.2,0,1.8,0
                                                                            c0.6,0,1.2,0,1.8,0l0,0c106.3,0.5,156,25.6,188.9,56.3l0,0C468.6,236.2,475.4,253.8,478.1,270.1L478.1,270.1z" />
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="modal-btns d-flex mt-4">
                            <button class="theme-btn white-btn" data-bs-toggle="modal" data-bs-target="#phoneModal">Cancel</button>
                            <button type="submit" class="theme-btn yellow-btn">Calling</button>
                        </div> -->
                        <div class="modal-btns text-center">
                            <button type="submit" class="theme-btn white-btn w-auto" data-bs-toggle="modal"
                                data-bs-target="#phoneModal" @click="fillDataNull()">Back </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade common-modal" id="setOperatorModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <button type="button" class="btn-close-svg" data-bs-dismiss="modal" aria-label="Close">
                        <svg xmlns="http://www.w3.org/2000/svg" width="7.905" height="7.905" viewBox="0 0 7.905 7.905">
                            <g id="Group_1469" data-name="Group 1469" transform="translate(-1480.904 -467.983)">
                                <line id="Line_340" data-name="Line 340" x1="5.784" y2="5.784"
                                    transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                <line id="Line_341" data-name="Line 341" x1="5.784" y1="5.784"
                                    transform="translate(1481.965 469.043)" fill="none" stroke="#d5a948"
                                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                            </g>
                        </svg>
                    </button>
                    <div class="heading">
                        <h3>
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 27.99 28">
                                <path class="cls-1"
                                    d="M861.06,566.12a10.38,10.38,0,0,1-3.7-.6c-.83-.29-1.65-.6-2.38-.88a18.84,18.84,0,0,1-5.08-2.93,37.75,37.75,0,0,1-5.19-5.12,23.36,23.36,0,0,1-4.13-8,8.76,8.76,0,0,1-.49-3.34,9,9,0,0,1,2.55-5.63,3.09,3.09,0,0,1,1.37-.86c.73-.21,1.46-.37,2.15-.51a2.74,2.74,0,0,1,.66-.09,2.34,2.34,0,0,1,2.07,1.29l.16.29c.29.53.55,1,.81,1.45l1.85,3.12a2.38,2.38,0,0,1-.31,3c-.38.39-.78.76-1.18,1.12l-1.19,1,.27.45c.52.88,1,1.71,1.57,2.49a14.79,14.79,0,0,0,4.63,4.1c.37.22.75.43,1.18.66l.08,0,1.15-1.29a12.5,12.5,0,0,1,.87-1,2.55,2.55,0,0,1,1.78-.84,3,3,0,0,1,1.54.49l4.08,2.39.72.41a2.26,2.26,0,0,1,1.16,2.19,8.87,8.87,0,0,1-.71,2.95,3.18,3.18,0,0,1-.78,1.09,9,9,0,0,1-5.49,2.51ZM846.82,540a1.19,1.19,0,0,0-.26,0c-.68.14-1.36.28-2,.47a1.38,1.38,0,0,0-.54.36,6.63,6.63,0,0,0-1.65,7.13,21.58,21.58,0,0,0,3.79,7.41,34.5,34.5,0,0,0,4.92,4.86,16.63,16.63,0,0,0,4.43,2.6c.89.34,1.69.64,2.49.92a8.33,8.33,0,0,0,3,.5,7.15,7.15,0,0,0,4.3-2,1.45,1.45,0,0,0,.33-.46,7.11,7.11,0,0,0,.56-2.34A.42.42,0,0,0,866,559l-.71-.41-4.1-2.4a1.27,1.27,0,0,0-.58-.23c-.16,0-.37.18-.52.32a10.39,10.39,0,0,0-.72.78l-.76.87c-.28.31-.5.57-.73.81a1.27,1.27,0,0,1-1,.45l-.38-.07-.26-.11c-.64-.34-1.17-.63-1.69-.94a16.44,16.44,0,0,1-5.2-4.62c-.6-.85-1.16-1.78-1.64-2.6l-.5-.84a1.29,1.29,0,0,1,.29-1.71l.73-.64L849,547c.42-.38.78-.7,1.11-1a.56.56,0,0,0,0-.74l-1.86-3.13c-.28-.5-.56-1-.83-1.5l-.16-.28A.51.51,0,0,0,846.82,540Z"
                                    transform="translate(-840.07 -538.12)" />
                            </svg>
                            Set operator
                        </h3>
                    </div>
                    <div>
                        <div class="set-operator-main d-flex flex-column" v-if="showOperatorSelect">
                            <div class="set-operator-table">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>
                                                <p class="text-center">First operator</p>
                                            </th>
                                            <th>
                                                <p class="text-center">Second operator</p>
                                            </th>
                                            <th>
                                                <p class="text-center">Third operator</p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="day in week" :key="day.name">
                                            <td>
                                                <p class="day">{{ day.name }}</p>
                                            </td>
                                            <td v-for="operator in day.operators" :key="operator.alt">
                                                <figure v-if="operator.image">
                                                    <img :src="operator.image" :alt="operator.alt">
                                                </figure>
                                                <button v-else class="add-operator-btn"
                                                    @click="openOperatorModel">+</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>


                        </div>
                        <div class="set-operator-main d-flex flex-column" v-if="showOperatorModel">
                            <div class="technician-list">
                                <ul>
                                    <li v-for="tech in technician" :key="tech.id" style="position:relative;">
                                        <figure><img :src="tech.tech_imageUrl" :alt="tech.fname"></figure>
                                        <!-- <a href='#' class="edit-serv edit-techn" @click="editTechnicianDataFetch(tech.id)">
                                        <button class="d-contents" data-bs-toggle="modal" data-bs-target="#EditTechnicianModal">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="17.504" height="17.504" viewBox="0 0 17.504 17.504">
                                            <g id="Group_2096" data-name="Group 2096" transform="translate(4781.272 4896.079)">
                                                <path id="Path_1693" data-name="Path 1693"
                                                d="M-4764.407-4890.8l-.773.774-4.642-4.642.773-.774a2.191,2.191,0,0,1,3.1,0l1.546,1.547A2.193,2.193,0,0,1-4764.407-4890.8Zm-10.831,9.281a.546.546,0,0,0,0,.774.545.545,0,0,0,.773,0l8.512-8.508-.776-.773Zm-3.869-3.867a.547.547,0,0,0,0,.774.547.547,0,0,0,.774,0l8.511-8.508-.774-.773Zm3.092,5.413a1.59,1.59,0,0,1-.434-.936,2.27,2.27,0,0,1-.338.033,2.171,2.171,0,0,1-1.546-.641,2.176,2.176,0,0,1-.642-1.547,2.164,2.164,0,0,1,.031-.314,1.632,1.632,0,0,1-.938-.459.71.71,0,0,1-.055-.084l-1.335,5.343,5.33-1.335C-4775.965-4879.93-4775.992-4879.946-4776.015-4879.969Z"
                                                fill="#d5a948" />
                                            </g>
                                            </svg>
                                        </button>
                                        </a> -->
                                        <p>{{ tech.fname }} {{ tech.lname }}</p>
                                    </li>

                                    <li>
                                        <a href="#" class="add-technician-btn" data-bs-toggle="modal"
                                            data-bs-target="#createTechnicianModal" @click="addTechResetData">+</a>
                                        <p class="mt-2">New </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="modal-btns d-flex mt-auto text-center">
                            <button class="theme-btn white-btn">Cancel</button>
                            <button type="submit" class="theme-btn yellow-btn">Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PlivoIncomingCall @sendCustomer-Number="sendCustomerNumber" :custName="customerNameSave"></PlivoIncomingCall>

</template>
<script>
import DialPad from './DialPad.vue';
import PlivoIncomingCall from './PlivoIncomingCall.vue';
import { EventBus } from '../event-bus.js';
import Swal from 'sweetalert2';

export default {
    components: {
        DialPad,
        PlivoIncomingCall,
        IncomingCallIcon: {
            template: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g id="Group_2280" data-name="Group 2280" transform="translate(6531.773 6924.288)">
                          <path id="Path_448" data-name="Path 448"
                            d="M-6520.2-6908.288a5.666,5.666,0,0,1-2.04-.334c-.457-.158-.912-.329-1.314-.48a10.4,10.4,0,0,1-2.8-1.62,20.51,20.51,0,0,1-2.861-2.822,12.828,12.828,0,0,1-2.278-4.43,4.882,4.882,0,0,1-.272-1.839,4.971,4.971,0,0,1,1.409-3.108,1.736,1.736,0,0,1,.757-.474c.4-.113.8-.2,1.183-.281a1.713,1.713,0,0,1,.363-.047,1.3,1.3,0,0,1,1.143.709l.088.161c.159.291.3.547.448.8l1.018,1.72a1.315,1.315,0,0,1-.17,1.642c-.21.217-.433.421-.649.618l-.66.58.149.249c.288.484.561.942.867,1.373a8.138,8.138,0,0,0,2.55,2.26c.208.122.417.238.649.365l.049.026.63-.713c.146-.17.3-.351.479-.521a1.4,1.4,0,0,1,.986-.464,1.669,1.669,0,0,1,.848.272c.672.4,2.251,1.318,2.251,1.318l.4.226a1.242,1.242,0,0,1,.636,1.206,4.911,4.911,0,0,1-.389,1.625,1.819,1.819,0,0,1-.432.6,4.964,4.964,0,0,1-3.027,1.386Zm-7.852-14.414a.651.651,0,0,0-.141.022c-.375.079-.751.16-1.12.264a.73.73,0,0,0-.3.194,3.976,3.976,0,0,0-1.134,2.48,3.888,3.888,0,0,0,.226,1.45,11.848,11.848,0,0,0,2.086,4.085,19.253,19.253,0,0,0,2.717,2.683,9.2,9.2,0,0,0,2.438,1.433c.492.186.932.351,1.374.5a4.616,4.616,0,0,0,1.681.277,3.927,3.927,0,0,0,2.371-1.116.781.781,0,0,0,.185-.256,3.887,3.887,0,0,0,.308-1.288.232.232,0,0,0-.13-.252l-.4-.224-2.257-1.323a.716.716,0,0,0-.321-.124.545.545,0,0,0-.288.175c-.144.14-.272.287-.4.431l-.42.477c-.152.174-.276.314-.4.449a.7.7,0,0,1-.529.245l-.212-.039-.142-.058c-.349-.189-.643-.35-.933-.521a9.111,9.111,0,0,1-2.863-2.547c-.334-.469-.64-.982-.907-1.434l-.275-.457a.714.714,0,0,1,.161-.944c.117-.108.238-.212.4-.355l.4-.351c.233-.213.428-.39.609-.58a.3.3,0,0,0,.028-.411l-1.021-1.725c-.16-.273-.311-.549-.461-.825l-.088-.157A.288.288,0,0,0-6528.052-6922.7Z"
                            transform="translate(0)" fill="#d5a948" />
                          <g id="Group_536" data-name="Group 536" transform="translate(-6522.149 -6924.288)">
                            <g id="Group_535" data-name="Group 535">
                              <g id="Group_533" data-name="Group 533">
                                <path id="Path_449" data-name="Path 449"
                                  d="M-6521.635-6917.911a.563.563,0,0,1-.4-.165.566.566,0,0,1,0-.8l5.247-5.247a.565.565,0,0,1,.8,0,.565.565,0,0,1,0,.8l-5.247,5.248A.565.565,0,0,1-6521.635-6917.911Z"
                                  transform="translate(6522.199 6924.288)" fill="#d5a948" />
                              </g>
                              <g id="Group_534" data-name="Group 534" transform="translate(0 0.733)">
                                <path id="Path_450" data-name="Path 450"
                                  d="M-6517.12-6917.911h-5.079v-5.079a.565.565,0,0,1,.564-.564.565.565,0,0,1,.565.564v3.95h3.95a.564.564,0,0,1,.565.565A.564.564,0,0,1-6517.12-6917.911Z"
                                  transform="translate(6522.199 6923.554)" fill="#d5a948" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>`
        },
        OutgoingCallIcon: {
            template: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                        <g id="Group_2281" data-name="Group 2281" transform="translate(6531.792 6757.468)">
                          <path id="Path_456" data-name="Path 456"
                            d="M-6520.219-6741.467a5.687,5.687,0,0,1-2.04-.333c-.457-.157-.911-.329-1.313-.481a10.34,10.34,0,0,1-2.8-1.62,20.411,20.411,0,0,1-2.862-2.823,12.841,12.841,0,0,1-2.277-4.43,4.874,4.874,0,0,1-.271-1.839,4.968,4.968,0,0,1,1.408-3.109,1.745,1.745,0,0,1,.757-.475c.4-.113.8-.2,1.183-.28a1.649,1.649,0,0,1,.363-.047,1.29,1.29,0,0,1,1.143.709l.088.16c.16.291.3.547.448.8l1.019,1.721a1.312,1.312,0,0,1-.17,1.642c-.21.218-.433.422-.649.618l-.66.58.149.249c.288.484.561.941.867,1.371a8.134,8.134,0,0,0,2.55,2.261c.208.122.417.239.65.365l.048.026.631-.713a6.985,6.985,0,0,1,.479-.521,1.4,1.4,0,0,1,.985-.465,1.674,1.674,0,0,1,.849.273c.671.4,2.25,1.317,2.25,1.317l.4.227a1.242,1.242,0,0,1,.636,1.206,4.926,4.926,0,0,1-.389,1.624,1.785,1.785,0,0,1-.432.6,4.963,4.963,0,0,1-3.026,1.386Zm-7.852-14.414a.64.64,0,0,0-.14.022c-.376.079-.751.158-1.121.262a.752.752,0,0,0-.3.194,3.983,3.983,0,0,0-1.134,2.48,3.914,3.914,0,0,0,.226,1.452,11.869,11.869,0,0,0,2.086,4.085,19.353,19.353,0,0,0,2.717,2.682,9.183,9.183,0,0,0,2.438,1.433c.493.187.932.353,1.374.5a4.6,4.6,0,0,0,1.682.277,3.933,3.933,0,0,0,2.37-1.117.79.79,0,0,0,.186-.256,3.905,3.905,0,0,0,.307-1.289.231.231,0,0,0-.131-.25l-.4-.225-2.257-1.322a.7.7,0,0,0-.321-.125.561.561,0,0,0-.288.174c-.144.14-.271.288-.4.433l-.419.477c-.153.173-.277.313-.4.449a.7.7,0,0,1-.527.245l-.213-.038-.142-.058c-.35-.191-.644-.351-.933-.523a9.107,9.107,0,0,1-2.864-2.546c-.334-.469-.638-.981-.907-1.434l-.273-.458a.711.711,0,0,1,.16-.944c.115-.106.237-.212.4-.354l.4-.352c.234-.212.427-.389.609-.579a.3.3,0,0,0,.028-.41l-1.021-1.726c-.159-.274-.311-.55-.461-.824l-.087-.159A.289.289,0,0,0-6528.071-6755.881Z"
                            transform="translate(0 0)" fill="#d5a948" />
                          <g id="Group_544" data-name="Group 544" transform="translate(-6522.168 -6757.468)">
                            <g id="Group_543" data-name="Group 543">
                              <g id="Group_541" data-name="Group 541">
                                <path id="Path_457" data-name="Path 457"
                                  d="M-6521.653-6751.092a.565.565,0,0,1-.4-.166.566.566,0,0,1,0-.8l5.247-5.247a.567.567,0,0,1,.8,0,.565.565,0,0,1,0,.8l-5.247,5.246A.56.56,0,0,1-6521.653-6751.092Z"
                                  transform="translate(6522.218 6757.468)" fill="#d5a948" />
                              </g>
                              <g id="Group_542" data-name="Group 542" transform="translate(0.732)">
                                <path id="Path_458" data-name="Path 458"
                                  d="M-6516.406-6751.825a.565.565,0,0,1-.565-.564v-3.95h-3.95a.564.564,0,0,1-.565-.565.564.564,0,0,1,.565-.564h5.079v5.079A.564.564,0,0,1-6516.406-6751.825Z"
                                  transform="translate(6521.486 6757.468)" fill="#d5a948" />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>`
        },
        MissCallIcon: {
            template: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_2279" data-name="Group 2279" transform="translate(6531.792 7005.135)">
                      <path id="Path_443" data-name="Path 443"
                        d="M-6520.314-6989.135a5.494,5.494,0,0,1-2.023-.341c-.453-.162-.9-.337-1.3-.493a10.184,10.184,0,0,1-2.775-1.659,20.568,20.568,0,0,1-2.839-2.89,13.307,13.307,0,0,1-2.259-4.535,5.146,5.146,0,0,1-.27-1.883,5.17,5.17,0,0,1,1.4-3.183,1.725,1.725,0,0,1,.751-.485c.4-.116.8-.206,1.173-.287a1.608,1.608,0,0,1,.36-.048,1.275,1.275,0,0,1,1.134.726l.087.165c.158.3.3.56.444.818l1.01,1.761a1.378,1.378,0,0,1-.169,1.682c-.208.223-.429.431-.644.632l-.654.594.148.255c.286.5.557.963.86,1.4a8.152,8.152,0,0,0,2.529,2.315c.206.125.414.244.645.374l.048.027.625-.73a7.134,7.134,0,0,1,.475-.533,1.371,1.371,0,0,1,.978-.477,1.624,1.624,0,0,1,.842.279c.666.406,2.232,1.349,2.232,1.349l.394.232a1.283,1.283,0,0,1,.63,1.235,5.15,5.15,0,0,1-.386,1.663,1.829,1.829,0,0,1-.428.615,4.855,4.855,0,0,1-3,1.419Zm-7.788-14.757a.63.63,0,0,0-.138.022c-.373.081-.746.162-1.112.269a.743.743,0,0,0-.3.2,4.136,4.136,0,0,0-1.124,2.539,4.116,4.116,0,0,0,.224,1.486,12.3,12.3,0,0,0,2.068,4.183,19.5,19.5,0,0,0,2.695,2.747,9.061,9.061,0,0,0,2.418,1.466c.489.191.924.36,1.362.517a4.44,4.44,0,0,0,1.668.284,3.849,3.849,0,0,0,2.35-1.145.81.81,0,0,0,.184-.262,4.11,4.11,0,0,0,.3-1.32.239.239,0,0,0-.13-.257l-.392-.229-2.239-1.354a.68.68,0,0,0-.318-.127.551.551,0,0,0-.286.179c-.142.143-.27.294-.392.442l-.416.488c-.152.177-.274.32-.4.46a.684.684,0,0,1-.523.25l-.211-.039-.141-.06c-.347-.2-.638-.36-.925-.535a9.142,9.142,0,0,1-2.841-2.608c-.331-.48-.632-1-.9-1.468l-.271-.469a.744.744,0,0,1,.159-.966c.114-.109.235-.217.4-.363l.4-.359c.232-.218.424-.4.6-.592a.318.318,0,0,0,.028-.42l-1.013-1.766c-.158-.281-.309-.563-.458-.845l-.086-.162A.287.287,0,0,0-6528.1-7003.892Z"
                        fill="#d5a948" />
                      <g id="Group_531" data-name="Group 531" transform="translate(-6522.467 -7005.135)">
                        <g id="Group_530" data-name="Group 530">
                          <g id="Group_527" data-name="Group 527">
                            <g id="Group_526" data-name="Group 526">
                              <path id="Path_444" data-name="Path 444"
                                d="M-6521.827-6998.458a.566.566,0,0,1-.4-.166.565.565,0,0,1,0-.8l5.547-5.547a.565.565,0,0,1,.8,0,.567.567,0,0,1,0,.8l-5.547,5.546A.569.569,0,0,1-6521.827-6998.458Z"
                                transform="translate(6522.391 7005.135)" fill="#d5a948" />
                            </g>
                          </g>
                          <g id="Group_529" data-name="Group 529">
                            <g id="Group_528" data-name="Group 528">
                              <path id="Path_445" data-name="Path 445"
                                d="M-6516.28-6998.458a.566.566,0,0,1-.4-.166l-5.547-5.546a.567.567,0,0,1,0-.8.565.565,0,0,1,.8,0l5.547,5.547a.565.565,0,0,1,0,.8A.568.568,0,0,1-6516.28-6998.458Z"
                                transform="translate(6522.391 7005.135)" fill="#d5a948" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>`
        },
    },
    props: {
        smsLogDetials: {
            type: Array,
            default: () => []
        },
        customerId: Number,
        customerPhones: Number,
        custName: String,
        customerLastName: String,
        callUUID: String,
        headerImage: String
        //smsLogDetials:Array :
    },
    name: 'OperatorSchedule',
    data() {
        return {
            imageUrl: '',
            selectedFile: null,
            copiedId: null,
            activeId: null,
            hideRecordIcon: true,
            isPlaying: false,
            customerIdGet: this.customerId,
            logs: [],
            smsLogDetailsOne: this.smsLogDetials,
            telNumber: '',
            numPadRows: [
                [1, 2, 3],
                [4, 5, 6],
                [7, 8, 9],
                ['*', 0, '#']
            ],
            showDialPad: false,
            mobileNumber: '',
            technician: null,
            showOperatorModel: false,
            showOperatorSelect: true,
            messageContent: '',
            customerPhonesNumber: null,
            customerNameSave: null,
            week: [
                { name: 'MON', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
                { name: 'TUE', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
                { name: 'WED', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
                { name: 'THU', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
                { name: 'FRI', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
                { name: 'SAT', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
                { name: 'SUN', operators: [{ image: 'images/user1.png', alt: 'First' }, {}, {}] },
            ]
        };
    },
    computed: {
        inputStyle() {
            return this.telNumber.length > 0 ? { border: 'unset' } : {};
        },
        formattedDuration() {
            return `${this.countdownMinutes} minutes ${this.countdownSeconds} seconds`;
        },
        latestMessage() {
            if (!this.smsLogDetials.length) return null;
            console.log("this.smsLogDetials", this.smsLogDetials);
            // Sort messages by creation date in descending order and get the first one
            return this.smsLogDetials.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
        },
    },
    watch: {
        // smsLogDetials: {
        //     deep: true,
        //     immediate: true,
        //     handler(newVal) {
        //         this.$nextTick(() => {
        //             this.scrollToBottom();
        //         });
        //         this.smsLogDetails = newVal;
        //     }
        // }
        latestMessage() {
            this.$nextTick(() => {
                this.scrollToLatest();
            });
        }
    },
    mounted() {
        this.sendCustomerNumber();
        this.fetchingDataAfterSubmit();
        //this.emitMakeCallClick();
    },
    methods: {
        // scrollToBottom() {
        //     const scrollContainer = this.$refs.scrollElement;
        //     if (scrollContainer) {
        //         scrollContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
        //     }
        // },
        scrollToLatest() {
            if (this.$refs.scrollElement) {
                this.$refs.scrollElement.scrollIntoView({ behavior: 'smooth' });
            }
        },
        copyToClipboard(url, logId) {
            if (url) {
                navigator.clipboard.writeText(url)
                    .then(() => {
                        console.log('Copied to clipboard: ', url);
                        this.copiedId = logId;
                        Swal.fire({
                            text: 'The URL has been copied to your clipboard.',
                            timer: 2000,
                            showConfirmButton: false,
                        });

                        setTimeout(() => {
                            this.copiedId = null;
                        }, 2000);
                    })
                    .catch(err => {
                        console.error('Could not copy text: ', err);
                    });
            }
        },
        hideClosed() {
            this.activeId = null;
            // this.isPlaying = false;
        },
        playAudio(id) {
            this.activeId = id;
            // this.isPlaying = true;
            // this.hideRecordIcon = true;
            // const audioPlayer = document.getElementById('audioPlayer');
            // audioPlayer.play();
            // this.$nextTick(() => {
            //     audio.play();   
            // });
        },
        formatCallDuration(durationInSeconds) {
            const hours = Math.floor(durationInSeconds / 3600);
            const minutes = Math.floor((durationInSeconds % 3600) / 60);
            const seconds = durationInSeconds % 60;
            const hoursFormatted = hours.toString().padStart(2, '0');
            const minutesFormatted = minutes.toString().padStart(2, '0');
            const secondsFormatted = seconds.toString().padStart(2, '0');
            return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;

        },
        isPhoneNumber(name) {
            // Regular expression to check if name contains any digit
            return /\d/.test(name);
        },
        getDatas(customerIdGet) {
            this.$emit('get-customer-details', customerIdGet);
            this.$emit('edit-customer-details');
        },
        openNewCustomer() {
            this.$emit('save_customer_new', this.customerPhones);
            // console.log("this.customerPhones", this.customerPhones);
        },
        handleNumberClick(num) {
            this.telNumber += num.toString();
        },
        backRemove() {
            if (this.telNumber.length > 0) {
                this.telNumber = this.telNumber.slice(0, -1);
            }
        },
        emitMakeCallClick(customerPhones) {
            const mobileNumber = this.customerPhones;
            this.$emit('makeCall-click', mobileNumber);
        },
        sendCustomerNumber(customerPhones) {
            // this.showDialPad = true;
            this.customerNameSave = this.custName;
            this.customerPhonesNumber = this.customerPhones;
            // console.log("this.customerPhonesNumber==", this.customerPhonesNumber);
        },
        fillDataNull() {
            this.showDialPad = '';
            this.messageContent = '';
        },
        openOperatorModel() {
            this.showOperatorModel = true;
            this.showOperatorSelect = false;
        },
        fetchingDataAfterSubmit() {
            axios.get('/api/technician?user_id=' + localStorage.getItem('usertoken'))
                .then((resp) => {
                    this.technician = resp.data.technician;
                })
                .catch(error => {
                    console.error('There was an error fetching the technician data:', error);
                });
        },
        // sendSMS(customerPhones) {
        //     var self = this;
        //     const phloUrl = '/api/send-sms';
        //     const senderId = localStorage.getItem('plivo_number');
        //     const recipientNumber = customerPhones;
        //     const messageContent = this.messageContent;
        //     const data = {
        //         From: senderId,
        //         To: recipientNumber,
        //         Text: messageContent,
        //         Type: 'sms'
        //     };

        //     axios.post(phloUrl, data)
        //         .then(response => {
        //             this.messageContent = '';
        //             const logData = {
        //                 user_id: localStorage.getItem('usertoken'),
        //                 sender: senderId,
        //                 receiver: recipientNumber,
        //                 text: messageContent,
        //                 type: 'sms',
        //                 // uuid:  '10wwee',
        //                 status: 'outgoing'
        //             };
        //             self.savelogdatabase(logData);
        //             EventBus.emit('sms-sent', recipientNumber);

        //         })
        //         .catch(error => {
        //             if (error.response) {
        //                 Swal.fire({
        //                     text: error.response.error,
        //                     showConfirmButton: false,
        //                     timer: 2000,
        //                     timerProgressBar: true
        //                 });
        //                 console.error('Error sending message:', error.response.data);
        //             } else {
        //                 // console.error('Error sending message:', error.message);
        //             }
        //         });
        // },

        // sendMessage(customerPhones) {
        //     const self = this;
        //     const senderId = localStorage.getItem('plivo_number');
        //     const recipientNumber = customerPhones;
        //     const messageContent = this.messageContent;

        //     let formData = new FormData();
        //     formData.append('From', senderId);
        //     formData.append('To', recipientNumber);
        //     formData.append('Text', messageContent);

        //     if (this.selectedFile) {
        //         formData.append('MediaUrl', this.selectedFile);
        //         formData.append('Type', 'mms');
        //         axios.post('/api/send-mms', formData, {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data'
        //             }
        //         })
        //             .then(response => {
        //                 this.messageContent = '';
        //                 this.selectedFile = null;
        //                 const logData = {
        //                     user_id: localStorage.getItem('usertoken'),
        //                     sender: senderId,
        //                     receiver: recipientNumber,
        //                     text: messageContent,
        //                     type: 'mms',
        //                     status: 'outgoing'
        //                 };
        //                 self.savelogdatabase(logData);
        //                 EventBus.emit('sms-sent', recipientNumber);
        //             })
        //             .catch(error => {
        //                 if (error.response) {
        //                     Swal.fire({
        //                         text: error.response.data.error,
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         timerProgressBar: true
        //                     });
        //                     console.error('Error sending message:', error.response.data);
        //                 } else {
        //                     console.error('Error sending message:', error.message);
        //                 }
        //             });
        //     } else {
        //         // Handle SMS sending
        //         axios.post('/api/send-sms', formData, {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data'
        //             }
        //         })
        //             .then(response => {
        //                 this.messageContent = '';
        //                 this.selectedFile = null;
        //                 const logData = {
        //                     user_id: localStorage.getItem('usertoken'),
        //                     sender: senderId,
        //                     receiver: recipientNumber,
        //                     text: messageContent,
        //                     type: 'sms',
        //                     status: 'outgoing'
        //                 };
        //                 self.savelogdatabase(logData);
        //                 EventBus.emit('sms-sent', recipientNumber);
        //             })
        //             .catch(error => {
        //                 if (error.response) {
        //                     Swal.fire({
        //                         text: error.response.data.error,
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         timerProgressBar: true
        //                     });
        //                     console.error('Error sending message:', error.response.data);
        //                 } else {
        //                     console.error('Error sending message:', error.message);
        //                 }
        //             });
        //     }
        // },
        removeImage() {
            this.selectedFile = null;
            this.imageUrl = '';
            this.$refs.fileInput.value = '';
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.selectedFile = file;
                this.imageUrl = URL.createObjectURL(file);
                console.log("this.selectedFile", this.selectedFile);
            }
        },
        sendMessage(customerPhones) {
            const self = this;
            const url = '/api/send-sms';
            const senderId = localStorage.getItem('plivo_number');
            const recipientNumber = customerPhones;
            const messageContent = this.messageContent;

            let formData = new FormData();
            formData.append('From', senderId);
            formData.append('To', recipientNumber);
            formData.append('Text', messageContent);
            
            const messageType = this.selectedFile ? "mms" : "sms";
            formData.append('Type', messageType);

            if (this.selectedFile) {
                formData.append('media_urls', this.imageUrl); 
            }
            

            axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    // console.log("response--", response);
                    this.messageContent = '';
                    const logData = {
                        user_id: localStorage.getItem('usertoken'),
                        sender: senderId,
                        receiver: recipientNumber,
                        text: messageContent,
                        type: messageType,
                        // uuid: '22sdwwddqssdddsddvvuuu11ssqswerssddeew',
                        media_urls: this.selectedFile ? this.selectedFile.name : null, 
                        status: 'outgoing'
                    };
                    // console.log("logData", logData);
                    this.selectedFile = null;
                    self.savelogdatabase(logData);
                    EventBus.emit('sms-sent', recipientNumber);
                })
                .catch(error => {
                    if (error.response) {
                        Swal.fire({
                            text: error.response.data.error,
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        });
                        console.error('Error sending message:', error.response.data);
                    } else {
                        console.error('Error sending message:', error.message);
                    }
                });
        },
        savelogdatabase(logData) {
            axios.post('/api/saveLogs', logData)
                .then(logResponse => {
                    // console.log('Log saved successfully:', logResponse.data);
                })
                .catch(logError => {
                    if (logError.response) {
                        console.error('Error saving log:', logError.response.data);
                    } else {
                        // console.error('Error saving log:', logError.message);
                    }
                });
        },
        getSmsList() {
            const userId = localStorage.getItem('usertoken');
            axios.get(`/api/logsList?user_id=${userId}&type=sms`)
                .then(response => {
                    if (response.data.status === 1) {
                        this.logs = response.data.data;
                        // console.log("this.logs", this.logs);
                    } else {
                        console.error('Failed to fetch logs');
                    }
                })
                .catch(error => {
                    console.error('Error fetching logs:', error.response ? error.response.data : error.message);
                });
        },
        hangup_Call() {
            this.showDialPad = '';
            if (plivoBrowserSdk && plivoBrowserSdk.client) {
                plivoBrowserSdk.client.hangup();
            } else {
                console.error('Plivo SDK client is not available');
            }
        },
    }
};
</script>