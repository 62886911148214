<template>
    <section class="pos-rel-index">
        <div class="calender-listing ">
            <ul class="owl-carousel calender-carousel-horizontal dragscroll">
                <li class="item active" @click="pickedDate(todayDateClicked)">
                    <button class="calendar-btn" :style="buttonStyle">
                        <h5>Today, {{ today_date }}</h5>
                        <p>{{ totalTodayAppoitment }} Appoinment</p>
                    </button>
                </li>
                <li v-for="(data, index) in otherAppointmentData" :key="index" @click="pickedDate(data.dateRecord)"
                    :class="activeMonthClass[index]">
                    <button class="calendar-btn" v-on:click="setActiveDay(index)" :style="buttonStyle">
                        <h5>{{ convertingDate(data.dateRecord) }}</h5>
                        <p>{{ data.appointmentCount }} </p>
                    </button>
                </li>
            </ul>
        </div>
        <!-- <InActiveTechList :technician="technician"/> -->
    </section>
    <section class="pos-rel-index" :class="{ scrolled }" style="position: sticky;top: 0px;z-index: 9990;">
        <div class="calendar-sticky d-none" :class="{ scrolled }">
            <li v-if="!selectedDateText" class="item">
                <!-- <button class="calendar-btn-first p-2" :style="buttonStyle">
                    <h5 style="font-weight: 100;">TODAY</h5>
                    <p>{{ totalTodayAppoitment }}</p>
                </button> -->
                <button class="calendar-btn-second p-2"
                    style="padding: 0 !important; overflow: hidden; background-color: var(--colorA);">
                    <h5 style="font-weight: 600; font-size: 18px; padding: 9px 0px;">TODAY</h5>
                    <div style="background-color: #ffffffde; padding-top: 3px;">
                        <p class="cel-ico" style="font-size: 20px;color: var(--colorB);">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16.13 16.97">
                                <path class="cls-1"
                                    d="m15.79,2.85l-.09-.13-.06-.07s-.08-.05-.12-.08c-.32-.27-.67-.41-1.06-.41h-.91v1.41c0,.92-.75,1.66-1.66,1.66s-1.65-.74-1.65-1.66v-1.41h-4.35v1.41c0,.92-.74,1.66-1.65,1.66s-1.65-.74-1.65-1.66v-1.41h-.96c-.07,0-.14,0-.2.02-.46.09-.83.32-1.09.68-.23.3-.34.63-.34.96v11.46s.01.11.02.19c.03.33.17.64.41.91.35.39.79.59,1.32.59h12.59c.15-.01.32-.02.47-.06.48-.12.85-.4,1.1-.83.15-.26.22-.49.22-.72V3.85c0-.35-.11-.68-.34-1Zm-.43,12.36c0,.47-.34.87-.81.95-.07.01-.13.02-.2.02H1.74c-.48,0-.89-.36-.95-.83,0-.07-.02-.14-.02-.21V6.29h14.59v8.92Z" />
                                <g>
                                    <path class="cls-1"
                                        d="m4.89.65v2.92c0,.36-.29.66-.65.66s-.65-.3-.65-.66V.65c0-.36.29-.65.65-.65s.65.29.65.65Z" />
                                    <path class="cls-1"
                                        d="m12.55.65v2.92c0,.36-.3.66-.66.66s-.65-.3-.65-.66V.65c0-.36.29-.65.65-.65s.66.29.66.65Z" />
                                </g>
                            </svg>
                            {{ totalTodayAppoitment }}
                        </p>
                        <p style="font-size: 12px;color: var(--colorB);margin-top: -5px;padding-bottom: 10px;">Appts</p>
                    </div>
                </button>
            </li>
            <li v-else class="item-main">
                <button class="calendar-btn-second p-2"
                    style="padding: 0 !important; overflow: hidden; background-color: var(--colorA)">
                    <!-- <h5>Today</h5> -->
                    <p style="font-weight: 600; font-size: 18px; padding: 9px 0px;">{{ selectedDateText }}</p>
                    <div style="background-color: #ffffffde; padding-top: 3px;">
                        <p class="cel-ico" style="font-size: 20px;color: var(--colorB);">
                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16.13 16.97">
                                <path class="cls-1"
                                    d="m15.79,2.85l-.09-.13-.06-.07s-.08-.05-.12-.08c-.32-.27-.67-.41-1.06-.41h-.91v1.41c0,.92-.75,1.66-1.66,1.66s-1.65-.74-1.65-1.66v-1.41h-4.35v1.41c0,.92-.74,1.66-1.65,1.66s-1.65-.74-1.65-1.66v-1.41h-.96c-.07,0-.14,0-.2.02-.46.09-.83.32-1.09.68-.23.3-.34.63-.34.96v11.46s.01.11.02.19c.03.33.17.64.41.91.35.39.79.59,1.32.59h12.59c.15-.01.32-.02.47-.06.48-.12.85-.4,1.1-.83.15-.26.22-.49.22-.72V3.85c0-.35-.11-.68-.34-1Zm-.43,12.36c0,.47-.34.87-.81.95-.07.01-.13.02-.2.02H1.74c-.48,0-.89-.36-.95-.83,0-.07-.02-.14-.02-.21V6.29h14.59v8.92Z" />
                                <g>
                                    <path class="cls-1"
                                        d="m4.89.65v2.92c0,.36-.29.66-.65.66s-.65-.3-.65-.66V.65c0-.36.29-.65.65-.65s.65.29.65.65Z" />
                                    <path class="cls-1"
                                        d="m12.55.65v2.92c0,.36-.3.66-.66.66s-.65-.3-.65-.66V.65c0-.36.29-.65.65-.65s.66.29.66.65Z" />
                                </g>
                            </svg>
                            {{ this.dateAppoint }}
                        </p>
                        <p style="font-size: 12px;color: var(--colorB);margin-top: -5px;padding-bottom: 10px;">Appts</p>
                    </div>
                    <!-- <p style="font-size: 23px;">{{ selectedDateTextDate }}</p> -->
                </button>
            </li>
        </div>
        <InActiveTechList :conditionMets="conditionMets" :absent-technicians="absentTechnicians"
            :technician="technician" :openPopIdGet="openPopIdGet" @hideMod="$emit('hideMod', $event)"
            :totalAppointments="totalAppointments" />
    </section>
</template>

<script>
import moment from "moment";
import { eventBus } from '../event-bus';
import InActiveTechList from './InActiveTechList.vue'

export default {
    components: {
        InActiveTechList,
    },
    props: {
        technician: {
            type: Array,
            default: () => []
        },
        updateGraphData: {
            type: Array,
            default: () => []
        },
        parentMessage: {
            type: String,
            default: ''
        },
        openPopIdGet: {
            type: String,
            default: true
        },
        totalAppointments: {
            type: Array,
            required: true,
        },
        conditionMets: {
            type: String,
            default: true
        },
    },
    data() {
        return {
            absentTechnicians: [],
            scrolled: false,
            saveDateLocal: '',
            selectedDateText: '',
            selectedDateTextDate: '',
            activeDayIndex: 0,
            today_date: '',
            today_date_sec: '',
            totalTodayAppoitment: '',
            otherAppointmentData: [],
            dateAppoint: '',
            getDateRecord: '',
            todayDateClicked: '',
            activeMonthClass: [],
            colorA: localStorage.getItem('colorA') || '#000000',
            colorB: localStorage.getItem('colorB') || '#000000',
            desiredOpacity: 0.3,
            localToday:''
        };
    },
    mounted() {
        this.nonActiveTechnician(new Date());
        window.addEventListener('scroll', this.handleScroll);
        this.$nextTick(() => {
            const $carousel = $(this.$el).find('.owl-carousel');
        });
        // eventBus.$on('updateSettings', (newSettings) => {
        //     this.settings = newSettings;
        // });
    },

    beforeCreate() {
        window.removeEventListener('scroll', this.handleScroll);
        axios.get('/api/appointment?user_id=' + localStorage.getItem('usertoken'), {
            headers: {
                "Accept": "application/json"
            },
        }).then((resp) => {
            this.todayDateClicked = resp.data['todayData'][0].dateRecord;
            console.log("this.todayDateClicked222222", this.todayDateClicked);

            this.today_date = moment(resp.data['todayData'][0].dateRecord).format("dddd MMM DD");
            this.today_date_sec = moment(resp.data['todayData'][0].dateRecord).format("DD");
            this.totalTodayAppoitment = resp.data['todayData'][0].appointmentCount;
            setTimeout(() => {
                this.otherAppointmentData = resp.data['otherDayData'];
                this.getDateRecord = this.otherAppointmentData.map(item => item.dateRecord)[0] || 0;
                if (this.getLocalDate === this.getDateRecord) {
                    this.dateAppoint = this.otherAppointmentData.map(item => item.appointmentCount)[0] || 0;
                    console.log("this.this.dateAppoint", this.dateAppoint);
                } else {
                    this.dateAppoint = 0;
                }
            }, 1000);


        });

    },
    computed: {
        buttonStyle() {
            return {
                '--button-background': this.convertColorToRGBA(this.colorA, 0.5),
            };
        },

    },
    methods: {
        nonActiveTechnician(selectedDate = new Date()) {
            this.$emit('updateGraphData', this.updateGraphData);
            let todayDate = moment(selectedDate).format('YYYY-MM-DD');
            let userId = localStorage.getItem('usertoken');

            axios.get('/api/absent-technician-slot', {
                params: {
                    user_id: userId,
                    date: todayDate 
                }
            })
                .then((resp) => {
                    if (resp.data.status) {
                        this.absentTechnicians = resp.data.absentTechnicians;
                    } else {
                        this.absentTechnicians = [];
                    }
                })
                .catch(error => {
                    console.error('There was an error fetching the technician data:', error);
                });
        },
        handleScroll() {
            this.scrolled = window.scrollY > 0; // Check scroll position
        },
        convertColorToRGBA(color, opacity) {
            const hexToRgb = (hex) =>
                hex
                    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
                    .substring(1)
                    .match(/.{2}/g)
                    .map((x) => parseInt(x, 16));

            const [r, g, b] = hexToRgb(color);
            return `rgba(${r}, ${g}, ${b}, ${opacity})`;
        },
        hexToRGBA(hex, opacity) {
            // return 'rgba(' + (hex = hex.replace('#', '')).match(new RegExp('(.{' + hex.length / 3 + '})', 'g')).map(function (l) { return parseInt(hex.length % 2 ? l + l : l, 16) }).concat(isFinite(opacity) ? opacity : 1).join(',') + ')';
        },
        setActiveDay(index) {
            this.activeDayIndex = index;
        },
        convertingDate(date) {
            if (moment().format('MMM') == moment(date).format("MMM")) {
                this.activeMonthClass.push('item');
                return moment(date).format("ddd DD");
            }
            else {
                this.activeMonthClass.push('item item-next-month');
                return moment(date).format("MMM DD");
            }

        },
        pickedDate(pickedDate) {
            this.localToday = moment(pickedDate).format('YYYY-MM-DD');
            this.nonActiveTechnician(this.localToday);
            console.log("this.localToday",this.localToday);
            this.$emit('updateDate', this.localToday);
            const openPopIdGet = this.openPopIdGet;
            this.$emit('isConditionMet');
            const targetId = "#userData" + openPopIdGet;
            $(targetId).removeClass('showModel');
            $(targetId).removeClass('show');
            $('#userDatacollapse' + openPopIdGet).collapse('hide');
            let ele = document.getElementById('bookingNewCustomer');
            if (ele) {
                ele.style.display = 'none';
            }
            this.formateDateForTech(pickedDate);
            this.getLocalDate = pickedDate;
            if (pickedDate === this.todayDateClicked) {
                this.selectedDateText = null;
            } else {
                this.selectedDateText = moment(pickedDate).format("MMM DD").toUpperCase();
                this.selectedDateTextDate = moment(pickedDate).format("DD").toUpperCase();;
            }
            this.$emit('datePicked', pickedDate);
            const items = document.querySelectorAll(".item");
            items.forEach(item => {
                item.classList.remove("active");
            });
            event.target.closest("li.item").classList.add("active");
        },

        takingAction() {
            axios.get('/api/appointment?user_id=' + localStorage.getItem('usertoken'), {
                headers: {
                    "Accept": "application/json"
                },
            }).then((resp) => {
                this.todayDateClicked = resp.data['todayData'][0].dateRecord;
                // console.log("this.todayDateClicked", this.todayDateClicked);
                this.today_date = moment(resp.data['todayData'][0].dateRecord).format("dddd MMM DD");
                this.totalTodayAppoitment = resp.data['todayData'][0].appointmentCount;
                this.otherAppointmentData = resp.data['otherDayData'];
                // console.log("this.otherAppointmentData", this.otherAppointmentData);
            });

        },
        formateDateForTech(selectedDateText) {
            this.getDateRecord = this.otherAppointmentData.map(item => item.dateRecord)[0] || 0;
            if (selectedDateText === this.getDateRecord) {
                this.dateAppoint = this.otherAppointmentData.map(item => item.appointmentCount)[0] || 0;
            } else {
                this.dateAppoint = 0;
            }
        }

    },

}
</script>
<style scoped>
.calender-carousel-horizontal {
    display: flex;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
}

.calender-carousel-horizontal .item {
    flex: 0 0 auto;
    margin-right: 10px;
}

.calender-carousel-horizontal .item button {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: #fff;
    text-align: center;
}

.calender-carousel-horizontal .item button.active {
    color: #fff;
}

.calender-carousel-horizontal .item-next-month button {
    background: #eaeaea;
    /* opacity: 20%; */
}

.calender-carousel-horizontal .item-next-month h5 {
    font-weight: bold;
}

.calender-listing ul li.item.active .calendar-btn {
    background-color: var(--button-background);
    border: 1px solid var(--colorA) !important;
    text-align: left;
}

button.calendar-btn-second.p-2,
button.calendar-btn-first.p-2 {
    width: 90px;
    height: 95px;
    font-size: 18px;
    border-radius: 5px;
    background-color: var(--button-background);
    border: 1px solid var(--colorA) !important;
    color: var(--colorD);
}
</style>