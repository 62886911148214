<template>
    <div v-show="!fullModal" class="modal fade common-modal" id="addegiftModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <AdditionalSellGiftCard @closeAdditionalModal="showMainModal" :showSellGiftCard="false"
            :showCustomerGiftCard="true" :giftcodeData="AdditionalgiftcodeData"
            :giftcardObject="AdditionalgiftcardObject" ref="deleteGiftCard"></AdditionalSellGiftCard>
    </div>
    <div v-show="fullModal">
        <div v-show="showFirstModal" class="modal fade" id="openModelCheckout" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content extramod container">
                    <div class="model-padding">
                        <div class="row">
                            <div class="col-md-4 col-4 side-effect"
                                style="flex: 1 1 41%;max-width: 375px;min-height: 630px !important;">
                                <div class="modal-header justify-content-center">
                                    <h5 class="modal-title color-D" id="staticBackdropLabel">CHECKOUT</h5>
                                </div>
                                <div v-show="!barcodeInfo" class="py-4 service-in" id="barcodeInfo">
                                    <div class="input-container service-ad">
                                        <label class="model-inputs" for="card">Add Service</label>
                                        <label class="svg-size">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 19.88 22.59">
                                                <path class="cls-1"
                                                    d="m17.41,13.22c-1.53-.17-3.06-.25-4.59-.24-1.36,0-2.72.1-4.08.26-.31.03-.61.09-.9.19-.24.09-.49.13-.74.12H.74c-.13-.01-.25-.01-.38,0-.23.04-.38.25-.34.48.03.18.17.31.34.34.14.01.28.01.42,0h6.09c-.1.51-.1,1.04,0,1.55.08.91.84,1.61,1.75,1.62,1.89.16,3.78.16,5.67,0l1.33-.1c-.08.24-.19.47-.32.69-.2.28-.43.53-.68.76-2.01,1.84-4.63,2.87-7.35,2.89-2.18.03-4.37,0-6.56,0-.14-.02-.28-.02-.42,0-.2.05-.32.25-.29.46,0,.19.16.34.35.35h6.56c.74,0,1.48-.06,2.22-.18,2.37-.38,4.57-1.48,6.3-3.15.62-.55,1-1.31,1.06-2.13.02-.17.11-.32.25-.41.44-.42.88-.86,1.31-1.3.15-.16.27-.36.35-.56.07-.14.12-.29.12-.45.04-.64-.41-1.04-1.12-1.18Zm-9.73,1.45c.11-.5.42-.67,1.08-.62-.09.69-.45,1.32-1,1.75-.14-.36-.16-.75-.08-1.13Z" />
                                                <path class="cls-1"
                                                    d="m12.98,11.12c.64-.16,1.2-.54,1.59-1.08-.64.43-1.43.54-2.16.32-.36-.13-.66-.4-.82-.75.55.02,1.09-.13,1.55-.42-1.36.03-1.9-.34-1.85-1.27l.26.14c.91.52,2.03.51,2.93-.03.37-.23.67-.56.87-.95.25-.4.63-.7,1.08-.85.54-.26,1.19-.03,1.45.51.03.07.06.14.08.22.16.5.16,1.03,0,1.53-.05.2-.13.39-.23.56-.77,1.45-2.3,2.35-3.94,2.31-.27-.02-.55-.07-.81-.15v-.1Z" />
                                                <path class="cls-1"
                                                    d="m17.94,5.54c-.38-.23-.84-.28-1.26-.15.06-.16.09-.28.14-.39.56-1.47,1.19-2.93,1.76-4.39.04-.1.09-.21.14-.3.16-.25.47-.36.75-.26.28.09.45.36.42.65-.01.1-.04.19-.07.29l-1.76,4.36c-.04.06-.04.12-.11.2Z" />
                                            </svg>
                                        </label>
                                        <a href="#" class="btn btn-primary btn-reseem color-yellow border-0"
                                            style="width: 200px !important;">Select</a>
                                        <div class="circle-image plus" @click="showAddServiceCat">
                                            <svg style="margin-top: 2px;" xmlns="http://www.w3.org/2000/svg" width="16"
                                                height="16" fill="currentColor" class="bi bi-plus-lg"
                                                viewBox="0 0 16 16">
                                                <path fill-rule="evenodd"
                                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="input-container removeSelect" :class="{ selected: cashPayment !== '' }">
                                        <label class="model-inputs" for="card">Cash payment</label>
                                        <label class="svg-size payment">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path
                                                    d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c10.6 1.6 42.1 6.7 55.1 10c17.1 4.3 27.5 21.7 23.2 38.9s-21.7 27.5-38.9 23.2c-9.3-2.4-37.6-7-48.9-8.7c-32.1-4.8-59.6-2.4-78.5 4.9c-18.3 7-25.9 16.9-27.9 28c-1.9 10.7-.5 16.8 1.3 20.6c1.9 4 5.6 8.5 12.9 13.4c16.2 10.8 41.1 17.9 73.3 26.7l2.8 .8c28.4 7.7 63.2 17.2 89 34.3c14.1 9.4 27.3 22.1 35.5 39.7c8.3 17.8 10.1 37.8 6.3 59.1c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.2-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.4 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.7 .5-16.8-1.3-20.6c-1.9-4-5.6-8.5-12.9-13.4c-16.2-10.8-41.1-17.9-73.3-26.7l-2.8-.8c-28.4-7.7-63.2-17.2-89-34.3c-14.1-9.4-27.3-22.1-35.5-39.7c-8.3-17.8-10.1-37.8-6.3-59.1C25 114.1 53 89.3 86 76.7c13-5 27.2-8.2 42-10V32c0-17.7 14.3-32 32-32z" />
                                            </svg>
                                        </label>
                                        <input class="cash_amount_paid" type="text" id="cash" name="cash"
                                            v-model="cashPayment" placeholder="Amount" autocomplete="off"
                                            @input="getData" @keydown="handleKeyDown">

                                        <div class="circle-image section-cros" v-show="removeIcoButton"
                                            style="padding-top: 6px;">
                                            <span id="icon_changeO" class="icon_change" @click="cashPaymentEmpty">
                                                <svg data-v-e73c3a1a="" version="1.0" id="katman_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 1436 1054" xml:space="preserve" style="width: 29px;">
                                                    <path data-v-e73c3a1a=""
                                                        d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                                                        c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                                                        c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="circle-image" v-show="!removeIcoButton">
                                            <span>All</span>
                                        </div>
                                    </div>
                                    <div class="input-container removeSelect" id="cashCard"
                                        :class="{ 'selected': isTyping }">
                                        <label class="model-inputs" for="card">Card payment</label>
                                        <label class="svg-size payment">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30.78 20.27">
                                                <path class="cls-1"
                                                    d="m2.56,0h25.67c.68,0,1.33.27,1.81.75.48.48.75,1.13.75,1.81v15.15c0,.68-.27,1.33-.75,1.81-.48.48-1.13.75-1.81.75H2.56c-.68,0-1.33-.27-1.81-.75-.48-.48-.75-1.13-.75-1.81V2.56c0-.68.27-1.33.75-1.81.48-.48,1.13-.75,1.81-.75h0Zm25.67,1.42H2.56c-.3,0-.59.12-.8.33-.21.21-.33.5-.33.8v15.15c0,.3.12.59.33.8.21.21.5.33.8.33h25.67c.3,0,.59-.12.8-.33.21-.21.33-.5.33-.8V2.56c0-.3-.12-.59-.33-.8-.21-.21-.5-.33-.8-.33Z" />
                                                <polygon class="cls-2"
                                                    points="30.55 4.45 30.55 7.71 30.55 8.19 30.07 8.19 .71 8.19 .24 8.19 .24 7.71 .24 4.45 .24 3.98 .71 3.98 30.07 3.98 30.55 3.98 30.55 4.45" />
                                                <path class="cls-2"
                                                    d="m5.3,11.08c.34,0,.67.06.98.17.03,0,.05.02.08.03-.12.09-.23.19-.33.29-.62.62-.97,1.46-.97,2.34s.35,1.72.97,2.34c.11.11.22.2.33.29-.03.01-.05.02-.08.03-.31.11-.64.17-.98.17-.78,0-1.5-.32-2.01-.83-.51-.51-.83-1.22-.83-2.01s.32-1.49.83-2.01c.51-.51,1.22-.83,2.01-.83Z" />
                                                <path class="cls-2"
                                                    d="m6.37,11.91c.51-.51,1.22-.83,2.01-.83s1.49.32,2.01.83c.51.51.83,1.22.83,2.01s-.32,1.49-.83,2.01c-.51.51-1.22.83-2.01.83s-1.5-.32-2.01-.83c-.51-.51-.83-1.22-.83-2.01s.32-1.49.83-2.01Z" />
                                                <path class="cls-1"
                                                    d="m13.82,13.21h3.03c.37,0,.67.32.67.71s-.3.71-.67.71h-3.03c-.37,0-.67-.32-.67-.71s.3-.71.67-.71h0Zm5.28,0h3.03c.37,0,.67.32.67.71s-.3.71-.67.71h-3.03c-.37,0-.67-.32-.67-.71s.3-.71.67-.71h0Zm5.28,0h3.03c.37,0,.67.32.67.71s-.3.71-.67.71h-3.03c-.37,0-.67-.32-.67-.71s.3-.71.67-.71Z" />
                                            </svg>
                                        </label>
                                        <input class="cash_amount_paid" type="text" name="cash" v-model="cardPayment"
                                            @input="removeCardInputCross" @keydown="handleKeyDownSecond"
                                            placeholder="Amount" autocomplete="off">
                                        <div class="circle-image section-cros" v-show="removeIcoCardPaymentButton"
                                            style="padding-top: 6px;padding-right: 2px;">
                                            <span id="icon_changeO" class="icon_change" @click="cardPaymentEmpty">
                                                <svg data-v-e73c3a1a="" version="1.0" id="katman_1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                    viewBox="0 0 1436 1054" xml:space="preserve">
                                                    <path data-v-e73c3a1a=""
                                                        d="M718.5,453.8l224-224.3c20.4-20.4,53.3-20.4,73.6,0c20.4,20.4,20.4,53.3,0,73.6l-224,224.6l224,224
                          c20.4,20.4,20.4,53.3,0,73.6c-20.4,20.4-53.3,20.4-73.6,0l-224-224l-224.6,224c-20.4,20.4-53.3,20.4-73.6,0
                          c-20.4-20.4-20.4-53.3,0-73.6l224-224L420.4,303.2c-20.5-20.4-20.5-53.3-0.1-73.6s53.3-20.4,73.6,0l224.6,224V453.8z">
                                                    </path>
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="circle-image" v-show="!removeIcoCardPaymentButton">
                                            <span>All</span>
                                        </div>
                                    </div>

                                    <div class="input-container service-ad">
                                        <label class="model-inputs" for="card">Gift payment</label>
                                        <label class="svg-size">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 33.54 21.66">
                                                <path class="cls-1"
                                                    d="m3.19,0h27.17c.88,0,1.67.36,2.25.93.58.58.93,1.37.93,2.25v15.29c0,.88-.36,1.67-.93,2.25-.58.58-1.37.93-2.25.93H10.14s-.09.01-.13.01c-.05,0-.09,0-.13-.01H3.19c-.88,0-1.67-.36-2.25-.93C.36,20.14,0,19.34,0,18.47v-7.68s0-.05,0-.07,0-.05,0-.07V3.18C0,2.31.36,1.51.94.94c.58-.58,1.37-.94,2.25-.94h0Zm8.59,8.46c-.05.11-.11.26-.18.43l-.17.42c-.05.12-.1.26-.16.4,2.38-.51,3.64-1.29,4.17-2.08.19-.29.28-.57.27-.82,0-.25-.08-.49-.22-.7-.29-.44-.8-.75-1.33-.74-1.1,0-1.72,1.5-2.19,2.65-.07.18-.14.35-.18.44h0Zm-1.02-1.7c.63-1.44,1.51-2.99,3.4-2.99,1.08,0,2.1.59,2.67,1.47.29.46.47.99.48,1.56,0,.56-.16,1.15-.54,1.72-.34.5-.86,1-1.6,1.44h16.87V3.18c0-.46-.19-.88-.49-1.18s-.72-.49-1.18-.49H10.76v5.25h0Zm-5.94,3.2c-.73-.44-1.25-.93-1.59-1.43-.38-.57-.55-1.16-.54-1.72,0-.57.18-1.1.48-1.56.57-.88,1.6-1.47,2.67-1.47,1.9,0,2.78,1.57,3.41,3.02V1.51H3.19c-.46,0-.88.19-1.18.49-.3.3-.49.72-.49,1.18v6.78h3.31Zm3.58-1.07c-.07-.17-.13-.31-.18-.43-.04-.09-.11-.26-.18-.44-.47-1.15-1.09-2.65-2.19-2.66-.53,0-1.05.3-1.33.74-.14.21-.22.45-.22.7,0,.26.08.54.27.82.53.79,1.79,1.57,4.17,2.08l-.16-.4-.17-.42h0Zm-4.81,4.45c1.2.01,2.3-.2,3.26-.69.58-.29,1.11-.69,1.6-1.18H1.51v7c0,.46.19.88.49,1.18.3.3.72.49,1.18.49h6.06v-7.23c-.52.47-1.08.86-1.69,1.17-1.2.61-2.54.88-4,.86l.02-1.59h0Zm8.18-1.88c.47.43.98.79,1.53,1.1.99.55,2.1.9,3.34,1.01l-.14,1.59c-1.48-.13-2.8-.55-3.98-1.21-.63-.35-1.21-.77-1.75-1.25v7.43h19.6c.46,0,.88-.19,1.18-.49s.49-.72.49-1.18v-7H11.76Z">
                                                </path>
                                            </svg>
                                        </label>
                                        <!-- <input class="cash_amount_paid" type="text" id="cash" name="cash" value=""
                                        placeholder="Scan" autocomplete="off"> -->
                                        <a href="#" class="btn btn-primary btn-reseem color-yellow border-0" @click=""
                                            style="width: 200px !important; height: 40px;">Scan</a>
                                        <div title="Scan barCode" class="circle-image plus-gift-card scan plus"
                                            @click="initLiveScanner">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 28.36 29.01">
                                                <g>
                                                    <path class="cls-1" d="m8.12,7.68h1.48v13.65h-1.48V7.68Z"></path>
                                                    <path class="cls-1" d="m11.85,21.32V7.68h1.48v13.64h-1.48Z"></path>
                                                    <path class="cls-1" d="m22.66,7.68h1.48v13.65h-1.48V7.68Z"></path>
                                                    <path class="cls-1" d="m18.76,7.68h.94v13.65h-.94V7.68Z"></path>
                                                    <path class="cls-1" d="m6.21,21.33h-.44V7.68h.44v13.65Z"></path>
                                                    <path class="cls-1" d="m4.66,21.33h-.44V7.68h.44v13.65Z"></path>
                                                    <path class="cls-1" d="m7.04,7.68h.44v13.64h-.44V7.68Z"></path>
                                                    <path class="cls-1" d="m14.13,21.33h-.43V7.68h.43v13.65Z"></path>
                                                    <path class="cls-1" d="m16.18,7.68h.43v13.65h-.43V7.68Z"></path>
                                                    <path class="cls-1" d="m20.31,7.68h.44v13.66h-.44V7.68Z"></path>
                                                    <path class="cls-1" d="m21.58,7.68h.44v13.65h-.44V7.68Z"></path>
                                                    <path class="cls-1" d="m11.39,21.33h-.39V7.68h.39v13.65Z"></path>
                                                    <path class="cls-1" d="m14.97,7.68h.42v13.65h-.42V7.68Z"></path>
                                                    <path class="cls-1" d="m17.81,21.33h-.43V7.68h.43v13.65Z"></path>
                                                    <path class="cls-1" d="m10.01,7.68h.38v13.65h-.38V7.68Z"></path>
                                                </g>
                                                <g>
                                                    <path class="cls-1" d="m1,7.07H0V.5C0,.22.22,0,.5,0h6.57v1H1v6.07Z">
                                                    </path>
                                                    <path class="cls-1"
                                                        d="m7.07,29.01H.5c-.28,0-.5-.22-.5-.5v-6.57h1v6.07h6.07v1Z">
                                                    </path>
                                                    <path class="cls-1"
                                                        d="m27.86,29.01h-6.57v-1h6.07v-6.07h1v6.57c0,.28-.22.5-.5.5Z">
                                                    </path>
                                                    <path class="cls-1"
                                                        d="m28.36,7.07h-1V1h-6.07V0h6.57c.28,0,.5.22.5.5v6.57Z"></path>
                                                </g>
                                            </svg>
                                        </div>



                                    </div>
                                    <!-- <div v-if="scannedCode">
                                    <p>Scanned Barcode: {{ scannedCode }}</p>
                                </div> -->
                                    <div v-show="showVideoStream" ref="scanner" id="scannerM" class="scanner-container">
                                        <div>
                                            <button @click="closeCam" class="btn btn-danger">Close scanner</button>
                                        </div>
                                        <video autoplay="true" preload="auto" src="" muted="true" playsinline="true"
                                            style="width: 100%; height: 200px; border: 1px solid #ccc;"></video>
                                        <canvas class="drawingBuffer" style="display: none;" width="4"
                                            height="4"></canvas>
                                        <br clear="all">
                                    </div>
                                    <div class="input-container service-ad">
                                        <label class="model-inputs" for="card">Addtional Sell</label>
                                        <label class="svg-size">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30.14 30.2">
                                                <path class="cls-1"
                                                    d="m15.52,30.2c-.19,0-.37-.04-.5-.1-2.64-1.25-5.29-2.52-7.93-3.79-2.13-1.02-4.26-2.04-6.39-3.06-.5-.24-.68-.52-.68-1.07C.04,17.3.03,12.43,0,7.55c0-.45.15-.68.59-.88C5.26,4.53,9.92,2.36,14.58.17c.25-.12.47-.17.68-.17s.42.05.65.16c4.52,2.16,9.06,4.31,13.6,6.44.48.23.64.48.63,1.02-.02,1.69-.02,3.38-.02,5.08v4.04c0,1.83,0,3.62.01,5.42,0,.5-.15.76-.59.99-2.04,1.04-4.08,2.09-6.11,3.14-2.47,1.27-4.94,2.55-7.41,3.81-.13.07-.31.1-.5.1Zm3.81-18.12c-1,.49-1.93.95-2.87,1.37-.5.23-.6.52-.59.98.02,1.46.02,2.92.02,4.38v9.91s.31-.14.31-.14c.2-.09.32-.14.43-.19l3.39-1.71c2.76-1.4,5.53-2.8,8.3-4.19.48-.24.65-.51.65-1.02-.02-2.93-.01-5.86-.01-8.8v-5.06s-1.25.58-1.25.58c-.8.37-1.55.72-2.31,1.04-.56.23-.75.52-.74,1.12.04,1.88.02,3.77,0,5.65,0,.23-.1.59-.27.68-.91.5-1.84.96-2.82,1.45l-1.01.51v-7.15l-1.22.6ZM1.23,8.17v3.74c0,3.27,0,6.55-.02,9.82,0,.47.26.59.57.74l10.39,5c.44.21,1.96.93,2.47,1.17l.03-.17c.02-.09.03-.15.03-.2v-2.37c0-3.88,0-7.76-.02-11.64,0-.26-.3-.63-.54-.74-3.27-1.47-6.56-2.93-9.84-4.38l-2.25-.99c-.13-.06-.27-.11-.41-.16l-.34-.13-.07.32Zm1.47-.84c4.05,1.75,8.09,3.51,12.14,5.25.17.07.31.11.42.11l.17-.04c.98-.48,1.99-1,3-1.51l1.18-.6h0c-.06-.12-.1-.19-.11-.2-3.94-1.75-7.88-3.5-11.83-5.24-.12-.06-.27-.09-.39-.09-.07,0-.13.01-.18.03-1.07.45-2.13.92-3.23,1.4l-1.5.66s.29.19.33.2ZM11.51,3.12c.1.07.16.1.22.13,0,0,8.62,3.9,11.97,5.41.1.05.24.11.34.11.99-.45,1.89-.9,2.83-1.36l.69-.34-.15-.12c-.06-.05-.1-.08-.13-.1-3.94-1.82-7.87-3.63-11.8-5.43-.12-.05-.26-.09-.4-.09-.09,0-.17.01-.23.04-.89.39-1.77.81-2.68,1.24l-.84.4.18.11Z" />
                                            </svg>
                                        </label>
                                        <!-- <input class="cash_amount_paid" type="text" id="cash" name="cash" value=""
                                        placeholder="Scan" autocomplete="off"> -->
                                        <a href="#" class="btn btn-primary btn-reseem color-yellow border-0"
                                            style="width: 200px !important; height: 40px;">Scan</a>
                                        <div @click="initLiveScanner('additionalsell')"
                                            class="circle-image plus-gift-card scan plus">
                                            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 28.36 29.01">
                                                <g>
                                                    <path class="cls-1" d="m8.12,7.68h1.48v13.65h-1.48V7.68Z"></path>
                                                    <path class="cls-1" d="m11.85,21.32V7.68h1.48v13.64h-1.48Z"></path>
                                                    <path class="cls-1" d="m22.66,7.68h1.48v13.65h-1.48V7.68Z"></path>
                                                    <path class="cls-1" d="m18.76,7.68h.94v13.65h-.94V7.68Z"></path>
                                                    <path class="cls-1" d="m6.21,21.33h-.44V7.68h.44v13.65Z"></path>
                                                    <path class="cls-1" d="m4.66,21.33h-.44V7.68h.44v13.65Z"></path>
                                                    <path class="cls-1" d="m7.04,7.68h.44v13.64h-.44V7.68Z"></path>
                                                    <path class="cls-1" d="m14.13,21.33h-.43V7.68h.43v13.65Z"></path>
                                                    <path class="cls-1" d="m16.18,7.68h.43v13.65h-.43V7.68Z"></path>
                                                    <path class="cls-1" d="m20.31,7.68h.44v13.66h-.44V7.68Z"></path>
                                                    <path class="cls-1" d="m21.58,7.68h.44v13.65h-.44V7.68Z"></path>
                                                    <path class="cls-1" d="m11.39,21.33h-.39V7.68h.39v13.65Z"></path>
                                                    <path class="cls-1" d="m14.97,7.68h.42v13.65h-.42V7.68Z"></path>
                                                    <path class="cls-1" d="m17.81,21.33h-.43V7.68h.43v13.65Z"></path>
                                                    <path class="cls-1" d="m10.01,7.68h.38v13.65h-.38V7.68Z"></path>
                                                </g>
                                                <g>
                                                    <path class="cls-1" d="m1,7.07H0V.5C0,.22.22,0,.5,0h6.57v1H1v6.07Z">
                                                    </path>
                                                    <path class="cls-1"
                                                        d="m7.07,29.01H.5c-.28,0-.5-.22-.5-.5v-6.57h1v6.07h6.07v1Z">
                                                    </path>
                                                    <path class="cls-1"
                                                        d="m27.86,29.01h-6.57v-1h6.07v-6.07h1v6.57c0,.28-.22.5-.5.5Z">
                                                    </path>
                                                    <path class="cls-1"
                                                        d="m28.36,7.07h-1V1h-6.07V0h6.57c.28,0,.5.22.5.5v6.57Z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="barcodeInfo" class="pt-3 pb-4 bg-histry-collection">
                                    <!-- Gift Information -->
                                    <div>
                                        <h5 class="text-center">GIFT</h5>
                                        <div class="last-activity-list mt-0">
                                            <!-- E-Gift Box -->
                                            <div class="justify-content-between box egift-box d-flex history-add"
                                                style="height: 70px; margin: 0;">
                                                <div class="d-flex details mt-7">
                                                    <span>{{ giftType }}</span>
                                                    <div class="d-flex justify-content-between my-1">
                                                        <p class="color-yellow">{{ giftCode }}</p>
                                                    </div>
                                                </div>
                                                <div class="mt-7"
                                                    style="display: flex;flex-direction: column;align-items: end;">
                                                    <span class="mt-0">Balance</span>
                                                    <p class="color-yellow mt-1">{{ balanceAmount }} $</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- History Section -->
                                    <div>
                                        <p class="text-center mt-3">History</p>
                                        <div class="last-activity-list mt-2">
                                            <!-- Use a for loop for dynamic history items -->
                                            <div v-for="(historyItem, index) in historyItems" :key="index"
                                                class="justify-content-between box egift-box d-flex history-add"
                                                :style="'height:65px;'">
                                                <div class="d-flex details">
                                                    <p>{{ historyItem.sender }}</p>
                                                    <div class="d-flex justify-content-between my-1">
                                                        <span class="pr-2 font-w-2">Redeem by</span>
                                                        <p>{{ historyItem.receiver }}</p>
                                                    </div>
                                                </div>
                                                <div class=""
                                                    style="display: flex;flex-direction: column;align-items: end;">
                                                    <p class="mt-0">{{ historyItem.amount }}</p>
                                                    <p class="mt-1">{{ formatDate(historyItem.date) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Redeem Now Section -->

                                    <div class="row justify-content-center">
                                        <div class="col-md-auto text-center main-redeem">
                                            <h5 class="text-center my-3 color-yellow">REDEEM NOW</h5>
                                            <!-- Use a div as a wrapper for the SVG icon with a click event -->
                                            <div>
                                                <label class="svg-size">
                                                    <svg id="Layer_1" data-name="Layer 1"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.48 24.87">
                                                        <path class="cls-1"
                                                            d="m6.95,24.87c-.31,0-.57-.1-.77-.31s-.3-.46-.3-.76v-2.49l.52.5c-1.22-.02-2.32-.18-3.3-.48s-1.8-.68-2.46-1.11c-.25-.16-.42-.36-.52-.6-.1-.24-.14-.49-.09-.73.04-.25.14-.45.3-.63s.35-.28.58-.31c.23-.04.49.04.79.21.54.35,1.22.66,2.03.94.81.28,1.8.42,2.97.42.94,0,1.72-.12,2.32-.37.6-.24,1.05-.58,1.34-1,.29-.42.43-.91.43-1.47,0-.45-.1-.85-.3-1.18-.2-.33-.53-.62-.98-.85-.46-.24-1.06-.43-1.81-.59l-2.7-.58c-1.59-.35-2.77-.93-3.55-1.73-.78-.8-1.17-1.86-1.17-3.17,0-1.03.25-1.95.75-2.75.5-.8,1.2-1.44,2.1-1.91.9-.47,1.95-.74,3.16-.81l-.39.37V1.07c0-.3.1-.55.3-.76.2-.21.46-.31.77-.31.3,0,.55.1.76.31.21.21.31.46.31.76v2.38l-.45-.37c.8.02,1.66.18,2.57.48.91.31,1.69.74,2.33,1.3.21.17.34.38.41.6.06.23.06.45,0,.66-.06.21-.17.38-.33.52s-.36.22-.6.24c-.25.02-.51-.07-.79-.26-.56-.38-1.17-.69-1.85-.92s-1.47-.34-2.4-.34c-1.22,0-2.19.29-2.91.86s-1.07,1.33-1.07,2.25c0,.7.22,1.26.66,1.69.44.43,1.17.76,2.2.98l2.75.58c1.68.37,2.92.94,3.72,1.7.8.77,1.21,1.79,1.21,3.07,0,1-.25,1.87-.76,2.63-.51.76-1.2,1.36-2.08,1.81-.88.45-1.9.72-3.05.83l.45-.5v2.52c0,.3-.1.55-.3.76s-.46.31-.77.31Z" />
                                                    </svg>
                                                </label>
                                            </div>
                                            <!-- Input field for redeeming cash -->
                                            <input v-model="redeemAmountEgift"
                                                class="text-center btn-reseem cash_amount_paid" type="text" id="cash"
                                                name="cash" placeholder="50.00" autocomplete="off"
                                                style="width: 57%;height: auto;">
                                            <div class="circle-image section-cros"
                                                style="padding-top: 6px; display: none;">
                                            </div>
                                        </div>
                                        <p style="color:#ED4337;margin-left: 14px;margin-top: 10px;"
                                            v-if="redeemAmountError">Enter amount should be less than balance !</p>
                                        <br />


                                    </div>

                                    <!-- Cancel and Redeem Buttons -->
                                    <div class="d-flex justify-content-start mt-rem-7 py-3">
                                        <div class="d-flex align-items-center bg-font-set pl-3">
                                            <span class="dummy-img color-A">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 15.25 15.25" style="height: 10px;margin-right: 4px">
                                                    <path class="cls-1"
                                                        d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                    <path class="cls-1"
                                                        d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                </svg>
                                            </span>
                                            <div class="ml-2">
                                                <a href="#" @click="closeGiftPaymentTab"
                                                    class="px-2 btn-redeem-card color-A">
                                                    Cancel</a>
                                            </div>
                                        </div>
                                        <a style="cursor: pointer;" @click="redeemScanEgift(redeemAmountEgift)"
                                            class="btn-primary btn-redeem-card">Redeem this card</a>
                                    </div>
                                </div>
                                <div v-if="loading" class="text-center loader-h">Loading...</div>
                                <div v-show="!loading">
                                    <div class="pt-3  bg-histry-collection" v-if="showServiceAddS">
                                        <div>
                                            <h5 class="text-center color-yellow font-sizeW-18">ADD SERVICE</h5>
                                            <div class="booking-body-end pt-0">
                                                <div class="categories-list d-flex align-items-center">
                                                    <div class="listing">
                                                        <ul class="nav nav-tabs">
                                                            <li v-for="(cat, index) in category" :key="index">
                                                                <a class="category-li" data-toggle="tab"
                                                                    :href="'#home' + cat.id"
                                                                    @click="activateTab(index)">
                                                                    {{ cat.name }}
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                                <!-- service tage -->
                                                <div class="tab-content">

                                                    <div :id="'home' + cat.id" class="container tab-pane "
                                                        style="padding: 0px 0px 0px 10px !important;"
                                                        :class="{ active: activeTab === index }"
                                                        v-for="(cat, index) in category" :key="index"><br>
                                                        <div class="service-listing"
                                                            v-for="catservice in cat.servicelist" :key="catservice.id">
                                                            <label class="wi-label">
                                                                <input type="checkbox" name=""
                                                                    v-model="catservice.isSelected"
                                                                    @change="handleCheckboxChange(catservice)" />
                                                                <div class="icon-box"
                                                                    :class="{ 'selected': catservice.isSelected }">
                                                                    <div class="service-box all package d-block"
                                                                        :style="{ border: catservice.isSelected ? '1px solid #d5a948' : '' }">
                                                                        <div class="d-flex align-items-center">
                                                                            <figure class="side-padding m-0"
                                                                                :style="{ margin: catservice.isSelected ? '-2px !important' : '' }">
                                                                                <img :src="catservice.service_imageUrl"
                                                                                    alt="service"
                                                                                    :style="{ width: catservice.isSelected ? '53px !important' : '', height: catservice.isSelected ? '53px !important' : '' }">
                                                                            </figure>
                                                                            <div class="text text-card-h6">
                                                                                <h4 :title="catservice.name"
                                                                                    :style="{ color: catservice.isSelected ? 'var(--colorD) !important' : '#606060' }">
                                                                                    {{ catservice.name }}
                                                                                </h4>

                                                                                <p
                                                                                    :class="catservice.categories_id_color">
                                                                                    {{ catservice.shortcode ?? 'M1' }} -
                                                                                    {{ catservice.duration }}’
                                                                                </p>
                                                                            </div>
                                                                            <h6 class="ms-auto card-h6"
                                                                                :style="{ color: catservice.isSelected ? 'var(--colorD) !important' : '#606060' }">
                                                                                {{ catservice.price }} $
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-flex justify-content-start mt-rem-7 py-3">
                                            <div class="d-flex align-items-center bg-font-set pl-3">
                                                <span class="dummy-img gift-close-svg">
                                                    <svg id="Layer_1" data-name="Layer 1"
                                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.25 15.25">
                                                        <path class="cls-1"
                                                            d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                        <path class="cls-1"
                                                            d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                    </svg>
                                                </span>
                                                <div class="ml-2">
                                                    <a href="#" @click="closeAddServiceTab"
                                                        class="px-2 btn-redeem-card color-yellow">Cancel</a>

                                                </div>
                                            </div>
                                            <a @click="handleAddOnService" class="btn-primary btn-redeem-card ">Done</a>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7 col-7 pt-4 px-4">
                                <div class="d-flex h-100 flex-column justify-content-between ">
                                    <div>
                                        <div class=" " style="border-bottom: 1px solid var(--colorC);">
                                            <div class="row align-items-center">
                                                <div class="col-md-6">
                                                    <div
                                                        class="bg-icon-user d-flex justify-content-start align-items-center">
                                                        <span class="dummy-img">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.201"
                                                                height="16.859" viewBox="0 0 15.201 16.859">
                                                                <g id="Group_1632" data-name="Group 1632"
                                                                    transform="translate(4923.887 6021.4)">
                                                                    <g id="Group_1631" data-name="Group 1631">
                                                                        <path id="Path_1413" data-name="Path 1413"
                                                                            d="M-4916.286-6012.585a4.412,4.412,0,0,1-4.407-4.407,4.413,4.413,0,0,1,4.407-4.408,4.413,4.413,0,0,1,4.407,4.408A4.412,4.412,0,0,1-4916.286-6012.585Zm0-8.022a3.619,3.619,0,0,0-3.616,3.615,3.62,3.62,0,0,0,3.616,3.615,3.62,3.62,0,0,0,3.616-3.615A3.619,3.619,0,0,0-4916.286-6020.607Z"
                                                                            fill="#d5a948" />
                                                                        <path id="Path_1414" data-name="Path 1414"
                                                                            d="M-4923.887-6004.541l.038-2.617a4.866,4.866,0,0,1,4.861-4.86h.188a3.842,3.842,0,0,0,2.532,1,3.605,3.605,0,0,0,2.392-.9l.16-.089.171-.011a4.867,4.867,0,0,1,4.859,4.862v2.615Zm14.376-.792.035-1.825a4.083,4.083,0,0,0-4.02-4.067,4.28,4.28,0,0,1-2.772,1,4.4,4.4,0,0,1-2.8-1,4.084,4.084,0,0,0-3.992,4.069v1.787Z"
                                                                            fill="#d5a948" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        <div class="ml-2 justify-content-between">
                                                            <label class="model-inputs color-black" for="card">{{
                                                                customerDetails.fname +
                                                                "" + customerDetails.lname }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="d-flex justify-content-end al">
                                                        <svg id="Layer_1" data-name="Layer 1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 14.91 20.99">
                                                            <path class="cls-1"
                                                                d="m7.45,20.99l-.31-.24c-.29-.23-7.14-5.75-7.14-13.29C0,3.34,3.34,0,7.45,0s7.45,3.34,7.45,7.45c0,7.64-6.85,13.07-7.15,13.3l-.31.24Zm0-19.99C3.9,1,1,3.9,1,7.45c0,6.19,5.13,11.09,6.46,12.25,1.33-1.15,6.45-6,6.45-12.25,0-3.56-2.9-6.45-6.45-6.45Z" />
                                                            <path class="cls-1"
                                                                d="m7.45,11.46c-2.06,0-3.73-1.67-3.73-3.73s1.67-3.73,3.73-3.73,3.73,1.67,3.73,3.73-1.67,3.73-3.73,3.73Zm0-6.46c-1.5,0-2.73,1.22-2.73,2.73s1.22,2.73,2.73,2.73,2.73-1.22,2.73-2.73-1.22-2.73-2.73-2.73Z" />
                                                        </svg>
                                                        <label class="model-inputs al" for="card">{{ appointmentTimes
                                                            }}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-for="(appointment, index) in appointementList" :key="index"
                                                class="row align-items-center my-3">
                                                <div class="col-md-6">
                                                    <div
                                                        class="bg-font-set d-flex justify-content-start align-items-center 2">
                                                        <span @click="removeAppointment(appointment.id,)"
                                                            class="dummy-img">
                                                            <svg id="Layer_1" data-name="Layer 1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 15.25 15.25">
                                                                <path class="cls-1"
                                                                    d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                                <path class="cls-1"
                                                                    d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                            </svg>
                                                        </span>
                                                        <div class="ml-2 justify-content-between">
                                                            <!-- Use appointment details -->
                                                            <label class="model-inputs" style="cursor: unset;"
                                                                for="card">{{
                                                                    appointment.name }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="d-flex justify-content-end al">
                                                        <!-- Use appointment price -->
                                                        <label class="model-inputs al" for="card">{{
                                                            appointment.price }} $</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="ShowAdditionalGiftCardPayment"
                                                class="row align-items-center my-3">
                                                <div class="col-md-6">
                                                    <div
                                                        class="bg-font-set d-flex justify-content-start align-items-center 3">
                                                        <span @click="emptyAdditionalSellgift" class="dummy-img">
                                                            <svg id="Layer_1" data-name="Layer 1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 15.25 15.25">
                                                                <path class="cls-1"
                                                                    d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                                <path class="cls-1"
                                                                    d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                            </svg>
                                                        </span>
                                                        <div class="ml-2 justify-content-between">
                                                            <!-- Use appointment details -->
                                                            <label class="model-inputs" style="cursor: unset;"
                                                                for="card">Giftcard {{
                                                                    additionalSellGiftCardobject.gift_code ?
                                                                        additionalSellGiftCardobject.gift_code : "MDGIFR32"
                                                                }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="d-flex justify-content-end al">
                                                        <!-- Use appointment price -->
                                                        <label class="model-inputs al" for="card">{{
                                                            this.additionalGiftCardPayment
                                                            }}.00
                                                            $</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center my-3">
                                            <div class="col-md-6">
                                                <div
                                                    class="bg-icon-user d-flex justify-content-start align-items-center">
                                                    <div class="ml-4 justify-content-between">
                                                        <label class="model-inputs"
                                                            style="cursor: unset;padding-left: 20px;"
                                                            for="card">TOTAL</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="d-flex justify-content-end al">
                                                    <label class="model-inputs al" for="card">{{ totalAppointmentPrice
                                                        }}.00
                                                        $</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="ShowEgiftCardPayment" class="row align-items-center my-3">
                                            <div class="col-md-6">
                                                <div
                                                    class="bg-font-set d-flex justify-content-start align-items-center 4">
                                                    <span @click="emptyScanEgift" class="dummy-img">
                                                        <svg id="Layer_1" data-name="Layer 1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 15.25 15.25">
                                                            <path class="cls-1"
                                                                d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                            <path class="cls-1"
                                                                d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                        </svg>
                                                    </span>
                                                    <div class="ml-2 justify-content-between">
                                                        <!-- Use appointment details -->
                                                        <label class="model-inputs" style="cursor: unset;"
                                                            for="card">E-Gift
                                                            payment</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="d-flex justify-content-end al">
                                                    <!-- Use appointment price -->
                                                    <label class="model-inputs al" for="card">{{ this.redeemAmountEgift
                                                        }}.00
                                                        $</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="showCashPayment" class="row align-items-center my-3">
                                            <div class="col-md-6">
                                                <div
                                                    class="bg-font-set d-flex justify-content-start align-items-center 5">
                                                    <span class="dummy-img" @click="cashPaymentEmpty">
                                                        <svg id="Layer_1" data-name="Layer 1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 15.25 15.25">
                                                            <path class="cls-1"
                                                                d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                            <path class="cls-1"
                                                                d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                        </svg>
                                                    </span>
                                                    <div class="ml-2 justify-content-between">
                                                        <!-- Use appointment details -->
                                                        <label class="model-inputs" style="cursor: unset;"
                                                            for="card">Cash
                                                            payment</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="d-flex justify-content-end al">
                                                    <!-- Use appointment price -->
                                                    <label class="model-inputs al" for="card">{{ this.cashPayment }}.00
                                                        $</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="showCardPayment" class="row align-items-center my-3">
                                            <div class="col-md-6">
                                                <div
                                                    class="bg-font-set d-flex justify-content-start align-items-center 1">
                                                    <span class="dummy-img" @click="cardPaymentEmpty">
                                                        <svg id="Layer_1" data-name="Layer 1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 15.25 15.25">
                                                            <path class="cls-1"
                                                                d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                            <path class="cls-1"
                                                                d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                        </svg>
                                                    </span>
                                                    <div class="ml-2 justify-content-between">
                                                        <!-- Use appointment details -->
                                                        <label class="model-inputs" style="cursor: unset;"
                                                            for="card">Card
                                                            payment</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="d-flex justify-content-end al">
                                                    <!-- Use appointment price -->
                                                    <label class="model-inputs al" for="card">{{ this.cardPayment }}.00
                                                        $</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class=" " style="border-top: 1px solid var(--colorC);">
                                            <div class="row align-items-center my-3 ">
                                                <div class="col-md-6">
                                                    <div class="col-md-6">
                                                        <div
                                                            class="bg-icon-user d-flex justify-content-start align-items-center">
                                                            <div class="ml-4 justify-content-between">
                                                                <label class="model-inputs"
                                                                    style="cursor: unset;padding-left: 5px;"
                                                                    for="card">REMAINING</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="d-flex justify-content-end al">
                                                        <label class="model-inputs al" for="card">{{
                                                            calculateRemainingAmount() }}.00 $</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-end justify-content-between mb-3 text-center">
                                        <div class="col-md-4 ">
                                            <button @click="cancelPayment" type="button"
                                                class="btn btn-light btn-contant-c">Cancel</button>
                                        </div>
                                        <div class="col-md-8">
                                            <button type="button" class="btn btn-light w-100 btn-contant-c"
                                                @click="completeCheckoutPayment(this.remainingAmounts)">COMPLETE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showSecondModal" class="modal fade" id="secondModal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content extramod container">
                    <div class="model-padding">
                        <div class="row">
                            <div class="col-md-4 side-effect" style="flex: 41%; max-width: 375px;">
                                <div class="modal-header justify-content-center">
                                    <h5 class="modal-title color-D" id="staticBackdropLabel">CHECKOUT 2</h5>
                                </div>
                                <div class="pt-3 pb-4 bg-histry-collection d-none">
                                    <div>
                                        <h5 class="text-center">GIFT</h5>
                                        <div class="last-activity-list mt-0">
                                            <div class="justify-content-between box egift-box d-flex history-add"
                                                style="height: 70px; margin: 0;">
                                                <div class="d-flex details mt-7">
                                                    <span>E-Gift</span>
                                                    <div class="d-flex justify-content-between my-1">
                                                        <p class="color-yellow">65UIY6</p>
                                                    </div>
                                                </div>
                                                <div class="mt-7">
                                                    <span class="mt-0">Balance</span>
                                                    <p class="color-yellow mt-1">50.00 $</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-center mt-3">History</p>
                                        <div class="last-activity-list mt-2">
                                            <div class="justify-content-between box egift-box d-flex history-add"
                                                style="height: 65px;">
                                                <div class="d-flex details">
                                                    <p>Anya Tran</p>
                                                    <div class="d-flex justify-content-between my-1">
                                                        <span class="pr-2 font-w-2">Redeem by</span>
                                                        <p>Minnie Kong</p>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <p class="mt-0">-25.00 $</p>
                                                    <p class="mt-1">Jan 21, 2024</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="last-activity-list mt-2">
                                            <div class="justify-content-between box egift-box d-flex history-add"
                                                style="height: 80px;">
                                                <div class="d-flex details">
                                                    <p>Anya Tran</p>
                                                    <div class="d-flex justify-content-between my-1">
                                                        <span class="pr-2 font-w-2">Redeem by</span>
                                                        <p>Minnie Kong</p>
                                                    </div>
                                                </div>
                                                <div class="">
                                                    <p class="mt-0">-25.00 $</p>
                                                    <p class="mt-1">Jan 21, 2024</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-auto text-center main-redeem">
                                            <h5 class="text-center my-3 color-yellow">REDEEM NOW</h5>
                                            <label class="svg-size">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 13.48 24.87">
                                                    <path class="cls-1"
                                                        d="m6.95,24.87c-.31,0-.57-.1-.77-.31s-.3-.46-.3-.76v-2.49l.52.5c-1.22-.02-2.32-.18-3.3-.48s-1.8-.68-2.46-1.11c-.25-.16-.42-.36-.52-.6-.1-.24-.14-.49-.09-.73.04-.25.14-.45.3-.63s.35-.28.58-.31c.23-.04.49.04.79.21.54.35,1.22.66,2.03.94.81.28,1.8.42,2.97.42.94,0,1.72-.12,2.32-.37.6-.24,1.05-.58,1.34-1,.29-.42.43-.91.43-1.47,0-.45-.1-.85-.3-1.18-.2-.33-.53-.62-.98-.85-.46-.24-1.06-.43-1.81-.59l-2.7-.58c-1.59-.35-2.77-.93-3.55-1.73-.78-.8-1.17-1.86-1.17-3.17,0-1.03.25-1.95.75-2.75.5-.8,1.2-1.44,2.1-1.91.9-.47,1.95-.74,3.16-.81l-.39.37V1.07c0-.3.1-.55.3-.76.2-.21.46-.31.77-.31.3,0,.55.1.76.31.21.21.31.46.31.76v2.38l-.45-.37c.8.02,1.66.18,2.57.48.91.31,1.69.74,2.33,1.3.21.17.34.38.41.6.06.23.06.45,0,.66-.06.21-.17.38-.33.52s-.36.22-.6.24c-.25.02-.51-.07-.79-.26-.56-.38-1.17-.69-1.85-.92s-1.47-.34-2.4-.34c-1.22,0-2.19.29-2.91.86s-1.07,1.33-1.07,2.25c0,.7.22,1.26.66,1.69.44.43,1.17.76,2.2.98l2.75.58c1.68.37,2.92.94,3.72,1.7.8.77,1.21,1.79,1.21,3.07,0,1-.25,1.87-.76,2.63-.51.76-1.2,1.36-2.08,1.81-.88.45-1.9.72-3.05.83l.45-.5v2.52c0,.3-.1.55-.3.76s-.46.31-.77.31Z" />
                                                </svg>
                                            </label>
                                            <a href="#" class="btn btn-primary btn-reseem">50.00</a>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-start mt-rem-7 py-3">
                                        <div class="d-flex align-items-center bg-font-set pl-3">
                                            <span class="dummy-img">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 15.25 15.25">
                                                    <path class="cls-1"
                                                        d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                    <path class="cls-1"
                                                        d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                </svg>
                                            </span>
                                            <div class="ml-2">
                                                <a href="#" class="px-2 btn-redeem-card color-A">Cancel</a>

                                            </div>
                                        </div>
                                        <a href="#" class="btn-primary btn-redeem-card ">Redeem this card</a>
                                    </div>

                                </div>
                                <div class="pt-3 bg-histry-collection">
                                    <div>
                                        <h5 class="text-center color-A font-sizeW-18">ADD SERVICE</h5>
                                        <div class="booking-body-end pt-0">
                                            <div class="categories-list d-flex align-items-center">
                                                <div class="listing" style="max-width: calc(100% - 10px) !important;">
                                                    <ul class="nav nav-tabs">
                                                        <li v-for="(cat, index) in category" :key="index">
                                                            <a class="category-li inModel" data-toggle="tab"
                                                                :href="'#home' + cat.id" @click="activateTab(index)">
                                                                {{ cat.name }}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <!-- service tage -->
                                            <div class="tab-content">

                                                <div :id="'home' + cat.id" class="container tab-pane "
                                                    :class="{ active: activeTab === index }"
                                                    v-for="(cat, index) in category" :key="index"><br>
                                                    <div class="service-listing" v-for="catservice in cat.servicelist"
                                                        :key="catservice.id">
                                                        <label class="wi-label">
                                                            <input type="checkbox" name=""
                                                                v-model="catservice.isSelected"
                                                                @change="handleCheckboxChange(catservice)" />
                                                            <div class="icon-box"
                                                                :class="{ 'selected': catservice.isSelected }">
                                                                <div class="service-box all package d-block"
                                                                    :style="{ border: catservice.isSelected ? '1px solid #d5a948' : '' }">
                                                                    <div class="d-flex align-items-center">
                                                                        <figure class="side-padding m-0"
                                                                            :style="{ margin: catservice.isSelected ? '-2px !important' : '' }">
                                                                            <img :src="catservice.service_imageUrl"
                                                                                alt="service"
                                                                                :style="{ width: catservice.isSelected ? '53px !important' : '', height: catservice.isSelected ? '53px !important' : '' }">
                                                                        </figure>
                                                                        <div class="text text-card-h6">
                                                                            <h4 :title="catservice.name"
                                                                                :style="{ color: catservice.isSelected ? 'var(--colorD) !important' : '#606060' }">
                                                                                {{ catservice.name }}
                                                                            </h4>

                                                                            <p :class="catservice.categories_id_color">
                                                                                {{ catservice.shortcode ?? 'M1' }} -
                                                                                {{ catservice.duration }}’
                                                                            </p>
                                                                        </div>
                                                                        <h6 class="ms-auto card-h6"
                                                                            :style="{ color: catservice.isSelected ? 'var(--colorD) !important' : '#606060' }">
                                                                            {{ catservice.price }} $
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex justify-content-start mt-rem-7 py-3">
                                        <div class="d-flex align-items-center bg-font-set pl-3">
                                            <span class="dummy-img gift-close-svg">
                                                <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 15.25 15.25">
                                                    <path class="cls-1"
                                                        d="m1,15.25c-.26,0-.51-.1-.71-.29-.39-.39-.39-1.02,0-1.41L13.54.29c.39-.39,1.02-.39,1.41,0s.39,1.02,0,1.41L1.71,14.95c-.2.2-.45.29-.71.29Z" />
                                                    <path class="cls-1"
                                                        d="m14.25,15.25c-.26,0-.51-.1-.71-.29L.29,1.71C-.1,1.32-.1.68.29.29S1.32-.1,1.71.29l13.25,13.25c.39.39.39,1.02,0,1.41-.2.2-.45.29-.71.29Z" />
                                                </svg>
                                            </span>
                                            <div class="ml-2">
                                                <a href="#" class="px-2 btn-redeem-card color-A">Cancel</a>

                                            </div>
                                        </div>
                                        <a href="#" class="btn-primary btn-redeem-card ">Done</a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-7 p-4">
                                <div class="d-flex h-100 flex-column justify-content-between ">
                                    <div>
                                        <div class="row align-items-center my-3">
                                            <div class="col-md-6">
                                                <div
                                                    class="bg-icon-user d-flex justify-content-start align-items-center">
                                                    <div class="ml-4 justify-content-between">
                                                        <label class="model-inputs" style="cursor: unset;"
                                                            for="card">REMAINING</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="d-flex justify-content-end al">
                                                    <label class="model-inputs al" for="card">$</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex align-items-end justify-content-between text-center">
                                        <div class="col-md-4 ">
                                            <button type="button" class="btn btn-light btn-contant-c">Cancel</button>
                                        </div>
                                        <div class="col-md-8">
                                            <button type="button" class="btn btn-light w-100 btn-contant-c"
                                                @click="openSecondModal">COMPLETE</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdditionalSellGiftCard from './AdditionalSellGiftCard'
import axios from 'axios';
import Quagga from 'quagga';
import Swal from 'sweetalert2'

export default {
    components: {
        AdditionalSellGiftCard,
    },
    props: {
        showFirstModal: Boolean,
        technicianId: String,
        appointementId: String,
        appointementList: Array,
        totalAppointmentPrice: Number,
        remainingAmount: Number,
        customerDetails: Object,
        appointmentTimes: String
    },
    data() {
        return {
            isTyping: false,
            fullModal: true,
            loading: false,
            showSecondModal: false,
            showThirdModal: false,
            scannedCode: null,
            scannerStream: false,
            showVideoStream: false,
            category: [],
            searchValue: '',
            customer: '',
            activeTab: 0,
            category_id: '',
            selectedCustomer: [],
            user_id: "",
            barcodeInfo: false,
            barcodeData: [],
            giftType: 'E-Gift',
            giftCode: '',
            balanceAmount: '',
            giftCardHistory: [],
            historyItems: [],
            showServiceAddS: false,
            showSelectedService: [],
            cashPayment: '',
            showCashPayment: false,
            cardPayment: null,
            showCardPayment: false,
            redeemAmountEgift: '',
            ShowEgiftCardPayment: false,
            additionalGiftCardPayment: 0,
            ShowAdditionalGiftCardPayment: false,
            removeIcoButton: false,
            removeIcoCardPaymentButton: false,
            redeemAmountError: false,
            remainingAmounts: 0,
            additionalSellGiftCardobject: {},
            // checkout data
            service_idsArray: [],
            additional_amount: 0,
            sell_gift_card_id: '',
            sell_gift_amount: '',
            e_gift_id: '',
            currentTime: '',
            AdditionalgiftcardObject: {},
        };
    },
    emits: ['closeFirstModal', 'addOnServiceData', 'updateTotalPrice'],
    mounted() {
        this.user_id = localStorage.getItem('usertoken');
        this.updateCurrentTime();
        setInterval(this.updateCurrentTime, 1000);

    },
    methods: {
        updateCurrentTime() {
            // const now = new Date();
            // let hours = now.getHours();
            // let minutes = now.getMinutes();
            // const ampm = hours >= 12 ? 'PM' : 'AM';
            // hours = hours % 12;
            // hours = hours ? hours : 12;
            // minutes = minutes < 10 ? '0' + minutes : minutes;
            //this.currentTime = `${hours}:${minutes} ${ampm}`;
            //this.currentTime = this.$emit('fetch_appointment','appointment_time');

        },
        removeAppointment(id) {
            Swal.fire({
                title: 'Are you sure you want to remove this service?',
                showCancelButton: true,
                confirmButtonText: '<i class="fa fa-check"></i>',
                cancelButtonText: '<i class="fa fa-times"></i>'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete('/api/appointment-service-remove', { params: { appointment_id: this.appointementId, service_id: id } })
                        .then(response => {
                            this.$emit('fetch_appointment', this.appointementId);
                            Swal.fire({
                                text: response.data.message,
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true
                            });
                            this.$emit('refreshGraphs');
                        }).catch(error => {
                            Swal.fire({
                                text: 'At least one service is required',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true
                            });
                        });

                }
            }).catch(error => {
                Swal.fire({
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true
                });
            });
        },
        emptyAdditionalSellgift() {
            this.$refs.deleteGiftCard.removeCard()
            this.sell_gift_card_id = ''
            this.additionalGiftCardPayment = 0
            this.sell_gift_amount = 0
            this.ShowAdditionalGiftCardPayment = false
        },
        showMainModal(data) {

            this.fullModal = !this.fullModal;
            if (data) {
                this.additionalSellGiftCardobject = data
                this.sell_gift_card_id = data.e_gift_id
                this.additionalGiftCardPayment = data.amount ? data.amount : 0
                this.sell_gift_amount = this.additionalGiftCardPayment
                this.$emit("updateTotalPrice", this.additionalGiftCardPayment)
                if (this.additionalGiftCardPayment > 0) {
                    this.ShowAdditionalGiftCardPayment = true
                }
            }
        },
        completeCheckoutPayment(remainingAmount) {
            const serviceIds = this.appointementList.map(service => service.id).join(',');
            if (remainingAmount === 0) {
                const data = {
                    user_id: localStorage.getItem('usertoken'),
                    service_ids: serviceIds,
                    appointment_id: this.appointementId,
                    total_amount: this.totalAppointmentPrice,
                    additional_amount: this.additional_amount,
                    cash_amount: this.cashPayment || 0,
                    card_amount: this.cardPayment || 0,
                    sell_gift_card_id: this.sell_gift_card_id || null,
                    sell_gift_amount: this.sell_gift_amount || 0,
                    e_gift_id: this.e_gift_id || null,
                    egift_amount: this.redeemAmountEgift || 0
                };

                axios.post("/api/payment", data)
                    .then((response) => {
                        Swal.fire({
                            title: 'Transaction Successful',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        })
                        $('#openModelCheckout').modal('hide')
                        this.additional_amount = ''
                        this.sell_gift_amount = ''
                        this.additionalSellGiftCardobject = ''
                        this.additionalGiftCardPayment = ''
                        this.cardPayment = ''
                        this.cashPayment = ''
                        this.redeemAmountEgift = '';
                        this.ShowEgiftCardPayment = false;
                        this.barcodeInfo = false;
                        this.barcodeData = null;
                        this.emptyAdditionalSellgift()
                        this.$emit('refreshGraphs')
                    })
                    .catch((error) => {
                        Swal.fire({
                            text: 'Something went wrong!',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        });
                    });
            } else {
                Swal.fire({
                    text: "Remaining Amount should be zero",
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true
                });
            }
        }
        ,
        calculateRemainingAmount() {

            this.remainingAmounts = this.totalAppointmentPrice - this.redeemAmountEgift - this.cardPayment - this.cashPayment
            return this.remainingAmounts
        },
        redeemScanEgift(redeemAmount) {
            if (redeemAmount === '' || isNaN(redeemAmount)) {
                this.redeemAmountError = true;
                return;
            }
            if (parseFloat(redeemAmount) > this.balanceAmount) {
                this.redeemAmountError = true;
                return;
            }
            this.ShowEgiftCardPayment = true;
            this.barcodeInfo = false;
            this.redeemAmountError = false;
        },
        emptyScanEgift() {
            this.redeemAmountEgift = '';
            this.ShowEgiftCardPayment = false;
        },
        cashPaymentEmpty() {
            this.showCashPayment = false;
            this.removeIcoButton = false;
            this.cashPayment = null;
            const inputElement = document.querySelector('.removeSelect');
            if (inputElement) {
                inputElement.classList.remove('selected');
            }
        },
        handleKeyDown() {
            const inputElement = document.querySelector('.removeSelect');
            if (inputElement) {
                inputElement.classList.add('selected');
            }
        },
        handleKeyDownSecond() {
            this.isTyping = true;
            const inputElement = document.getElementById('cashCard');
            if (inputElement) {
                inputElement.classList.add('selected');
            }
        },

        cardPaymentEmpty() {
            this.showCardPayment = false;
            this.removeIcoCardPaymentButton = false;
            this.cardPayment = null
            const inputElement = document.getElementById('cashCard');
            if (inputElement) {
                inputElement.classList.remove('selected');
            }
        },
        handleAddOnService() {
            this.$emit('addOnServiceData', this.showSelectedService);
            this.barcodeInfo = false;
            let el = document.getElementById('barcodeInfo');
            if (el) {
                el.style.display = 'block';
            }
            this.showServiceAddS = false;
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });

        },
        getData() {
            this.showCashPayment = true;
            this.removeIcoButton = true;
        },
        removeCardInputCross() {
            if (!this.cardPayment) {
                this.isTyping = false;
            }
            this.showCardPayment = true;
            this.removeIcoCardPaymentButton = true;
        },

        showAddServiceCat() {
            this.barcodeInfo = false;
            let el = document.getElementById('barcodeInfo');
            if (el) {
                el.style.display = 'none';
            }
            this.showServiceAddS = true;
            this.callBackcategoriesApi();
        },
        closeAddServiceTab() {
            let el = document.getElementById('barcodeInfo');
            if (el) {
                el.style.display = 'block';
            }
            this.barcodeInfo = false;
            this.showServiceAddS = false;
        },
        closeGiftPaymentTab() {
            let el = document.getElementById('barcodeInfo');
            if (el) {
                el.style.display = 'block';
            }
            this.redeemAmountEgift = '';
            this.ShowEgiftCardPayment = false;
            this.barcodeInfo = false;
            this.barcodeData = null;
        },
        cancelPayment() {

            let el = document.getElementById('barcodeInfo');
            if (el) {
                el.style.display = 'block';
            }
            $('#openModelCheckout').modal('hide')
            this.additional_amount = '';
            this.sell_gift_amount = '';
            this.additionalSellGiftCardobject = '';
            this.additionalGiftCardPayment = '';
            this.cardPayment = '';
            this.cashPayment = '';
            this.redeemAmountEgift = '';
            this.ShowEgiftCardPayment = false;
            this.barcodeInfo = false;
            this.barcodeData = null;
            this.emptyAdditionalSellgift()
            this.$emit('refreshGraphs')
            // window.location.reload();
            this.showServiceAddS = false;
        },
        handleCheckboxChange(catservice) {

            if (!catservice) {
                return;
            }

            this.isServiceSelected = catservice.isSelected;
            if (catservice.isSelected) {
                this.showSelectedService.push(catservice);
            } else {
                const indexToRemove = this.showSelectedService.findIndex(service => service.id === catservice.id);
                if (indexToRemove !== -1) {
                    this.showSelectedService.splice(indexToRemove, 1);
                }
            }

        },
        callBackcategoriesApi() {
            this.loading = true;
            let data = {
                user_id: localStorage.getItem('usertoken'),
                techID: this.technicianId,
            }
            axios.post('/api/categoryServiceData', data)
                .then((resp) => {
                    this.category = resp.data.category;
                    if (resp.data.serviceListData[0] == null) {

                    } else {
                        let dummyCat = {
                            id: 0,
                            name: 'Recent',
                            servicelist: resp.data.serviceListData
                        }
                        this.category.push(dummyCat);
                        this.category.reverse();
                        this.$emit('refreshGraphs');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        activateTab(index) {
            this.activeTab = index;
        },
        getScanGiftCardHistroy(gift_id) {
            axios.post('/api/scan-gift-card-history', { user_id: this.user_id, gift_id: gift_id })
                .then(response => {
                    if (response.status === 200) {
                        this.giftCardHistory = response.data
                        const history = response.data.data
                        this.historyItems = history.map(history => ({
                            sender: history.customer_data.fname + " " + history.customer_data.lname,
                            receiver: history.customer_data.fname + " " + history.customer_data.lname,
                            date: history.updated_at,
                            amount: "-" + parseFloat(history.egift_amount) + ".00 $"
                        }));
                        this.giftCode = this.giftCardHistory.giftcard.gift_code;
                        this.barcodeInfo = true;
                    }
                })
                .catch(error => {
                    Swal.fire({
                        title: "No history found",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    });
                });
        },

        getGiftCardDetail(code) {
            axios.post('/api/scan-gift-card', { user_id: this.user_id, scan_id: code })
                .then(response => {
                    if (response.status === 200) {
                        this.barcodeData = response.data;
                        this.balanceAmount = this.barcodeData.data.remaining_amount;
                        this.e_gift_id = this.barcodeData.data.id
                        const giftId = response.data.data.e_gift_id;
                        this.getScanGiftCardHistroy(giftId);
                    }
                })
                .catch(error => {
                    Swal.fire({
                        title: "No record found",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    });
                });
        },
        openSecondModal() {
            this.callBackcategoriesApi();
            this.$emit('closeFirstModal');
            //this.showSecondModal = true;
        },
        triggerFileInput() {
            this.scannerStream = true;
        },
        handleFileChange(event) {
            const file = event.target.files[0];

            if (file) {
                this.decodeBarcode(file);
            }
        },
        decodeBarcode(file) {
            Quagga.decodeSingle(
                {
                    decoder: {
                        readers: ['code_128_reader'],
                    },
                    locate: true,
                    src: URL.createObjectURL(file),
                },
                (result) => {
                    if (result && result.codeResult && result.codeResult.code) {
                        this.scannedCode = result.codeResult.code;
                    } else {
                        this.scannedCode = 'No valid barcode found';
                    }
                }
            );
        },
        initLiveScanner(type) {
            this.showVideoStream = true;
            navigator.mediaDevices
                .getUserMedia({ video: true })
                .then((stream) => {
                    Quagga.init(
                        {
                            inputStream: {
                                name: 'Live',
                                type: 'LiveStream',
                                target: this.$refs.scanner,
                                constraints: {
                                    width: 400,
                                    height: 400,
                                    facingMode: 'environment',
                                    aspectRatio: { min: 1, max: 2 },
                                },
                                area: {
                                    top: '0%',
                                    right: '0%',
                                    left: '0%',
                                    bottom: '0%',
                                },
                            },
                            decoder: {
                                readers: ['code_128_reader'],
                            },


                        },
                        (err) => {
                            if (err) {
                                console.error(err);
                                return;
                            }
                            Quagga.start();
                        }
                    );


                    if (type === 'additionalsell') {
                        Quagga.onDetected(this.AdditionalhandleDetected);
                    }
                    else {
                        Quagga.onDetected(this.handleDetected);
                    }
                })
                .catch((err) => {
                    Swal.fire({
                        title: 'Error accessing camera !!',
                        text: "Camera Not Found",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    });

                });
        },
        AdditionalhandleDetected(result) {
            const user_id = localStorage.getItem('usertoken')
            try {
                axios.post("api/create-gift-card", { user_id: user_id, bar_code_id: result.codeResult.code }).then(response => {
                    if (response.status = 200) {
                        this.AdditionalgiftcodeData = response.data.giftcode
                        this.AdditionalgiftcardObject = response.data.giftcard
                    }
                    this.fullModal = false;
                    $('#addegiftModal').modal('show');
                }).catch(error => {

                    Swal.fire({
                        text: "Duplicate Barcode",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    })
                })
            }
            catch (err) {
                Swal.fire({
                    text: "Please select a gift card to sell.",
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true
                })
            }

            // this.giftcodeData = response.data.giftcode
            //             this.giftcardObject = response.data.giftcard
            this.closeCam()
        },
        handleDetected(result) {
            if (result && result.codeResult && result.codeResult.code) {
                this.scannedCode = result.codeResult.code;
                this.getGiftCardDetail(this.scannedCode);
                this.closeCam();

            } else {
                Swal.fire({
                    text: 'No Barcode found',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true
                });
            }
        },
        closeCam() {
            Quagga.offDetected(this.handleDetected);
            Quagga.stop();
            this.showVideoStream = false;
        }
    },

    beforeUnmount() {
        if (this.myObject) { // Ensure myObject is defined
            Quagga.stop(); // Or whatever you're calling
        }
    },

};
</script>

<style>
.scanner-container {
    width: 100%;
    height: 200px;
    border: 1px solid #ccc;
    position: relative;
}

.circle-image span {
    display: flex;
    justify-content: center;
    margin-top: 2px;
}
</style>